// DesignChoicesSpecial.jsx

import React, { useState, useEffect } from "react";

import { ILLUSTRATION_BUTTON_IMAGE_URLS } from "../../constants/illustrationsConsts";
import { ILLUSTRATION_STYLES } from "../../constants/illustrationsConsts";
import { ILLUSTRATION_AI_PROMPTS } from "../../constants/illustrationsConsts";

import { motion, AnimatePresence } from "framer-motion";

import {
  illustrationStylesMap,
  createStoryMap,
} from "../../constants/siteTextMap";
import "../../styles/DesignChoices.css";
import useFetchPrompts from "../hooks/useFetchPrompts";

// const [illustrationType, setIllustrationType] = useState([0, ILLUSTRATION_STYLES[0], ILLUSTRATION_BUTTON_IMAGE_URLS[0], ILLUSTRATION_AI_PROMPTS[0]])

export default function DesignChoicesSpecial(props) {
  const {
    language,
    illustrationType,
    setIllustrationType,
    setIllustrationObject,
    theme,
  } = props;

  const buttonsPathPrefix = theme?.buttonsPathPrefix || "button-images";

  const [gridVisible, setGridVisible] = useState(false);
  const [activityTimeout, setActivityTimeout] = useState(null);
  const [illustrationImages, setIllustrationImages] = useState([]);

  const illustrationPromptsFirebase = useFetchPrompts();

  const loadImage = (imageName) => {
    try {
      return require(`../../images/${buttonsPathPrefix}/${imageName}`);
    } catch (e) {
      console.error("Failed to load image:", imageName);
      try {
        return require(`../../images/button-images/${imageName}`);
      } catch (fallbackError) {
        console.error("Failed to load fallback image:", imageName);
        return null;
      }
    }
  };

  useEffect(() => {
    const imageNames = [
      "image1_oil.jpg",
      "image2_cartoon.jpg",
      "image3_realistic.jpg",
      "image4_comic.jpg",
      "image5_line.jpg",
      "image6_abstract.jpg",
      "image7_dreamlike.jpg",
      "image8_vintage.jpg",
      "image9_cyberpunk.jpg",
      "image10_picasso.jpg",
      "image11_vangogh.jpg",
      "image12_subdued.jpg",
      "image13_manga.jpg",
      "image14_collage.jpg",
      "image15_watercolor.jpg",
      "image16_emoji.jpg",
    ];

    const loadedImages = imageNames.map((imageName) => loadImage(imageName));
    setIllustrationImages(loadedImages);
  }, [buttonsPathPrefix]);

  useEffect(() => {
    const tempValue = 0; // later store this in state at applevl

    if (illustrationPromptsFirebase) {
      setIllustrationObject({
        value: tempValue,
        styleSelected,
        buttonUrl: ILLUSTRATION_BUTTON_IMAGE_URLS[tempValue],
        firebaseSeederPrompt: illustrationPromptsFirebase[styleSelected],
        styleSelectedPresentationText: illustrationStylesMap[language]
          ? illustrationStylesMap[language][styleSelected]
          : illustrationStylesMap["English"][styleSelected],
      });
    }
  }, [illustrationPromptsFirebase]);

  useEffect(() => {
    setIllustrationType([
      0,
      ILLUSTRATION_STYLES[0],
      ILLUSTRATION_BUTTON_IMAGE_URLS[0],
      ILLUSTRATION_AI_PROMPTS[0],
    ]);
  }, []);

  const toggleGridVisibility = () => {
    setGridVisible(!gridVisible);
  };

  const handleGridItemClick = (value, e) => {
    const gridItemElement = e.currentTarget;

    // Apply the pressed effect
    gridItemElement.classList.add("gridItemPressed");

    // Clear any existing timeout
    clearTimeout(activityTimeout);

    // Remove the pressed effect after 100ms
    setTimeout(() => {
      gridItemElement.classList.remove("gridItemPressed");
    }, 100);

    // Update the illustrationType state based on the clicked grid item
    const styleSelected = ILLUSTRATION_STYLES[value];
    setIllustrationType([
      value,
      styleSelected,
      ILLUSTRATION_BUTTON_IMAGE_URLS[value],
      illustrationPromptsFirebase[styleSelected],
    ]);

    setIllustrationObject({
      value,
      styleSelected,
      buttonUrl: ILLUSTRATION_BUTTON_IMAGE_URLS[value],
      firebaseSeederPrompt: illustrationPromptsFirebase[styleSelected],
      styleSelectedPresentationText: illustrationStylesMap[language]
        ? illustrationStylesMap[language][styleSelected]
        : illustrationStylesMap["English"][styleSelected],
    });
  };

  const styleSelected = illustrationType[1];

  return (
    <>
      <div className="gridContainer">
        <img
          onClick={toggleGridVisibility}
          src={illustrationImages[illustrationType[0]]}
          alt="illustration"
          className="thickBlackBorder"
          style={{ width: "175px", height: "175px" }}
        />

        <h4
          style={{ color: "grey", fontSize: "1.5em" }}
          onClick={toggleGridVisibility}
        >
          {illustrationStylesMap[language]
            ? illustrationStylesMap[language][styleSelected]
            : illustrationStylesMap["English"][styleSelected]}
        </h4>

        <AnimatePresence>
          {gridVisible && (
            <>
              <div
                style={{ border: "4px solid grey", backgroundColor: "grey" }}
              >
                <motion.div
                  className="gridMenu"
                  initial={{ opacity: 1 }}
                  exit={{ opacity: 0 }}
                  transition={{ duration: 0.5 }}
                >
                  {illustrationImages.map((imgSrc, index) => (
                    <div
                      key={index}
                      onClick={(e) => handleGridItemClick(index, e)}
                      className="gridItem"
                    >
                      <div className="gridItemImageContainer">
                        <img
                          src={imgSrc}
                          alt={`illustration-${index}`}
                          className="fixedSizeImage"
                        />
                      </div>
                    </div>
                  ))}
                </motion.div>
                <h4 style={{ fontFamily: "Arial Black", color: "black" }}>
                  {createStoryMap[language]?.chooseIllustrationStyle ||
                    createStoryMap["English"].chooseIllustrationStyle}
                </h4>
              </div>
            </>
          )}
        </AnimatePresence>
      </div>
    </>
  );
}
