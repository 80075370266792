import React from "react";
import { Icon } from "semantic-ui-react";
import { story2Map } from "../../../constants/siteTextMap.js";

const FileMetaData = ({
  ageLevel,
  readingLevel,
  prompt,
  language,
  languageEmoji,
  illustrationObject,
  dateCreatedDisplay,
  dateModifiedDisplay,
  currentLanguage,
  setShowMetaData,
  setShowPremiumFeatures_A,
}) => {
  return (
    <div style={{ backgroundColor: "#f5f5f5", padding: "5px" }}>
      <h1 className="center">File MetaData</h1>

      <h4 style={{ color: "black", fontSize: "1em", margin: "5px" }}>
        {ageLevel ? (
          <>
            <span>
              {" "}
              {story2Map[currentLanguage]?.ageLevel ||
                story2Map["English"].ageLevel}
              {": "}
              {ageLevel}{" "}
            </span>
            {readingLevel && <span> TEXT LEVEL: n/a</span>}
          </>
        ) : null}
      </h4>

      <h4 style={{ color: "black", fontSize: "1em", margin: "5px" }}>
        {prompt ? (
          <span>
            {" "}
            {story2Map[currentLanguage]?.prompt || story2Map["English"].prompt}
            {": "} {prompt}
          </span>
        ) : null}
      </h4>

      <h4 style={{ color: "black", fontSize: "1em", margin: "5px" }}>
        {language ? (
          <span>
            {" "}
            {story2Map[currentLanguage]?.language ||
              story2Map["English"].language}
            {": "} {language} {languageEmoji}
          </span>
        ) : null}
      </h4>

      {illustrationObject ? (
        <h4 style={{ color: "black", fontSize: "1em", margin: "5px" }}>
          {story2Map[currentLanguage]?.illustrationStyle ||
            story2Map["English"].illustrationStyle}
          : {illustrationObject.styleSelectedPresentationText}
        </h4>
      ) : null}

      <h4 style={{ color: "black", fontSize: "1em", margin: "5px" }}>
        <span>
          {" "}
          {story2Map[currentLanguage]?.created || story2Map["English"].created}
          {": "}
          {dateCreatedDisplay}
        </span>
      </h4>

      <h4 style={{ color: "black", fontSize: "1em", margin: "5px" }}>
        <span>
          {" "}
          {story2Map[currentLanguage]?.modified ||
            story2Map["English"].modified}
          {": "}
          {dateModifiedDisplay}
        </span>
      </h4>

      <div
        className="center"
        onClick={() => {
          setShowMetaData((prev) => !prev);
          setShowPremiumFeatures_A(false);
        }}
      >
        <Icon color="black" size="large" name="eye slash" />
      </div>
    </div>
  );
};

export default FileMetaData;
