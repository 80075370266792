// Home / StoryPanel.jsx

import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Container } from "semantic-ui-react";
import DisplayFirestoreImagesUser from "../general/DisplayFirestoreImagesUser";
import useFirebaseQuery from "../hooks/useFirebaseQuery";
import { createAvatar } from "@dicebear/core";
//import { botttsNeutral } from '@dicebear/collection';
import { adventurer } from "@dicebear/collection";
import Lottie from "lottie-react";
import simpleLoader from "../../images/simpleLoader.json";
// import * as style from '@dicebear/avatars-bottts-sprites';

import { homeMap } from "../../constants/siteTextMap";

function StoryPanel(props) {
  const { currentUser, db, firestoreUserData, setShowNavbar } = props;
  const {
    loading: loadingQuery,
    error: errorQuery,
    data: dataQuery,
  } = useFirebaseQuery(currentUser?.uid, db, "stories", "dateCreated");

  const {
    userDisplayName,
    avatarSeed,
    userId,
    currentLanguage,
    newUser,
    googleAuth,
  } = firestoreUserData || {};

  const avatar = createAvatar(adventurer, {
    seed: avatarSeed ? avatarSeed : userId,
  });
  const svgAvatar = avatar.toDataUriSync();

  let navigate = useNavigate();
  const showStory = (storyId) => {
    navigate("/story2/" + storyId);
  };
  // const handleProfileClick = () => { navigate("/profile") }

  useEffect(() => {
    setShowNavbar(true);
  }, []);

  if (newUser) navigate("/new-user-agreement");

  if (!currentUser) {
    return navigate("/");
  }
  if (errorQuery) return <h1>{errorQuery?.message}</h1>;
  if (loadingQuery)
    return (
      <div className="center">
        <Lottie
          animationData={simpleLoader}
          loop={true}
          style={{ height: 600, position: "relative" }}
        />
      </div>
    );

  if (!currentUser?.emailVerified) {
    navigate("/loginemailconfirm");
    console.log("❌ NOT VERIFIED ❌  ");
  }

  return (
    <>
      <Container>
        {userDisplayName && (
          <h2 className="center" style={{ fontSize: "3.5em" }}>
            {userDisplayName}
          </h2>
        )}

        <div className="center">
          {svgAvatar && (
            <img
              src={svgAvatar}
              alt=""
              height="200px"
              width="200px"

              // onClick={handleProfileClick}
            />
          )}
        </div>

        {dataQuery && (
          <h2 className="center">
            {homeMap[currentLanguage]?.stories || homeMap["English"].stories}{" "}
            {dataQuery?.length}
          </h2>
        )}

        <DisplayFirestoreImagesUser
          userId={currentUser?.uid}
          size={"40px"}
          showStory={showStory}
          {...props}
        />
      </Container>
    </>
  );
}

export default StoryPanel;
