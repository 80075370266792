// TransactionSuccessProduct.jsx

import React, { useState, useEffect } from "react";
import { Container, Header, Segment, Loader } from "semantic-ui-react";
import { useLocation } from "react-router-dom";
import { auth, db } from "../../firebase";
import { useAuthState } from "react-firebase-hooks/auth";
import { doc, getDoc, onSnapshot } from "firebase/firestore";

function TransactionSuccessProduct() {
  const [user] = useAuthState(auth);
  const [currentCredits, setCurrentCredits] = useState(null);
  const [recentPurchase, setRecentPurchase] = useState(null);
  const [loading, setLoading] = useState(true);
  const [debugInfo, setDebugInfo] = useState({});
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const sessionId = params.get("session_id");

  useEffect(() => {
    if (user && sessionId) {
      const docRef = doc(db, "users", user.uid);
      const unsubscribe = onSnapshot(
        docRef,
        (doc) => {
          if (doc.exists()) {
            const userData = doc.data();
            setCurrentCredits(userData.credit);

            // Find the most recent purchase that matches the session ID
            const recentPurchase = userData.purchases?.find(
              (purchase) => purchase.transactionId === sessionId
            );
            setRecentPurchase(recentPurchase);

            // Set loading to false after we've processed the data
            setLoading(false);
          } else {
            console.error("User document not found");
            setLoading(false);
          }
        },
        (error) => {
          console.error("Error fetching user data:", error);
          setLoading(false);
        }
      );

      return () => unsubscribe();
    } else if (!user) {
      // If there's no user, we should stop loading
      setLoading(false);
    }
  }, [user, sessionId]);

  if (loading) {
    return <Loader active>Loading transaction details...</Loader>;
  }

  if (!user) {
    return (
      <Container>
        <br />
        <h2>Please log in to view your transaction details.</h2>
      </Container>
    );
  }

  return (
    <Container>
      <br />
      <h2>Thank you for your purchase!</h2>
      <Segment>
        {recentPurchase ? (
          <div>
            <Header as="h3">
              {recentPurchase.displayName} Purchase Successful
            </Header>
            <p>Price: ${(recentPurchase.amount / 100).toFixed(2)} USD</p>
            <p>Credits Added: {recentPurchase.credits}</p>
            <p>
              Purchase Date:{" "}
              {recentPurchase.purchaseDate.toDate().toLocaleString()}
            </p>
            <p>Transaction Id: {recentPurchase.transactionId}</p>
            <p>
              Payment Method: {recentPurchase.paymentMethodType} (ending in{" "}
              {recentPurchase.lastFour})
            </p>
            <p>Email receipt mailed to: {user.email}</p>
          </div>
        ) : (
          <p>
            Unable to find purchase information. Please contact support if you
            believe this is an error.
          </p>
        )}
        <br />
        <p>
          Your current credit balance:{" "}
          {currentCredits !== null ? currentCredits : "Loading..."}
        </p>
      </Segment>
    </Container>
  );
}

export default TransactionSuccessProduct;
