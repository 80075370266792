// stripe/PurchaseCredits.jsx

import React, { useEffect, useState } from "react";
import { Grid, Container } from "semantic-ui-react";
import { useAuthState } from "react-firebase-hooks/auth";
import { auth, db } from "../../firebase";
import { loadStripe } from "@stripe/stripe-js";
//import toast from "react-hot-toast";
import axios from "axios";
import { doc, onSnapshot } from "firebase/firestore";

const baseUrl = process.env.REACT_APP_API_URL;

console.log("API Base URL:", process.env.REACT_APP_API_URL);

const STORY_CREDITS = [
  {
    id: "prod_XXXXX5",
    priceId: "price_1PfLzsE0O9nAbsVmJl1ScIOX",
    displayName: "5 Credits Pack",
    price: 2.0,
    credits: 5,
  },
  {
    id: "prod_XXXXX4",
    priceId: "price_1PfM0cE0O9nAbsVmGGjHul8I",
    displayName: "20 Credits Pack",
    price: 5.0,
    credits: 20,
  },
  {
    id: "prod_XXXXX3",
    priceId: "price_1PfOdHE0O9nAbsVmImoRhnHw",
    displayName: "50 Credits Pack",
    price: 10.0,
    credits: 50,
  },
  {
    id: "prod_XXXXX2",
    priceId: "price_1PfOe1E0O9nAbsVmvG0dToO8",
    displayName: "150 Credits Pack",
    price: 25.0,
    credits: 150,
  },
  {
    id: "prod_XXXXX1",
    priceId: "price_1PfOjME0O9nAbsVmRpcXe6lR",
    displayName: "500 Credits Pack",
    price: 50.0,
    credits: 500,
  },
];

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY);

const PurchaseCredits = () => {
  const [user, loading] = useAuthState(auth);
  const [credits, setCredits] = useState(null);
  const [error, setError] = useState(null);

  useEffect(() => {
    if (!user) return;

    const unsubscribe = onSnapshot(doc(db, "users", user.uid), (doc) => {
      if (doc.exists()) {
        setCredits(doc.data().credit);
      }
    });

    return () => unsubscribe();
  }, [user]);

  const handleCheckout = async (productId) => {
    try {
      const stripe = await stripePromise;
      if (!stripe) {
        throw new Error("Failed to load Stripe");
      }

      // Find the credit package
      const creditPackage = STORY_CREDITS.find(
        (credit) => credit.id === productId
      );

      if (!creditPackage) {
        throw new Error("Invalid product selected");
      }

      // Match exactly what the server expects in req.body
      const requestData = {
        creditPackage: {
          id: creditPackage.id,
          priceId: creditPackage.priceId,
          displayName: creditPackage.displayName,
          price: creditPackage.price,
          credits: creditPackage.credits,
        },
        email: user.email,
        userId: user.uid,
      };

      console.log("Sending request to:", `${baseUrl}/api/checkout_products`);
      console.log("Request data:", JSON.stringify(requestData, null, 2));

      const response = await axios({
        method: "post",
        url: `${baseUrl}/api/checkout_products`,
        data: requestData,
        headers: {
          "Content-Type": "application/json",
        },
        validateStatus: null, // Allow any status code for debugging
      });

      console.log("Server response:", response.data);

      if (response.data && response.data.id) {
        localStorage.setItem(
          "pendingCreditPurchase",
          JSON.stringify({
            ...creditPackage,
            timestamp: Date.now(),
            userId: user.uid,
          })
        );

        const result = await stripe.redirectToCheckout({
          sessionId: response.data.id,
        });

        if (result.error) {
          throw new Error(result.error.message);
        }
      }
    } catch (error) {
      console.error("Checkout error details:", {
        message: error.message,
        response: error.response?.data,
        status: error.response?.status,
      });

      setError(
        error.response?.data?.error ||
          error.message ||
          "Failed to create checkout session"
      );
    }
  };

  return (
    <Container text>
      <div
        style={{ textAlign: "center", marginTop: "40px", marginBottom: "40px" }}
      >
        <h1
          style={{
            fontSize: "2.5em",
            marginBottom: "20px",
            color: "#2185d0", // Semantic UI blue
          }}
        >
          Purchase Story Credits
        </h1>
        <h2
          style={{
            fontSize: "1.8em",
            color: "#666",
          }}
        >
          Current Credits: {credits !== null ? credits : "Loading..."}
        </h2>

        {error && (
          <div style={{ color: "red", marginTop: "10px" }}>Error: {error}</div>
        )}
      </div>

      <Grid columns={2} stackable centered style={{ margin: "20px 0" }}>
        <Grid.Row>
          {STORY_CREDITS.map((credit) => (
            <Grid.Column
              key={credit.id}
              style={{
                padding: "10px",
                display: "flex",
                justifyContent: "center",
              }}
            >
              <div
                onClick={() => handleCheckout(credit.id)}
                style={{
                  width: "180px",
                  height: "180px",
                  border: "2px solid #2185d0",
                  borderRadius: "10px",
                  padding: "20px",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "space-between",
                  alignItems: "center",
                  backgroundColor: "white",
                  boxShadow: "0 4px 8px rgba(0,0,0,0.1)",
                  transition: "transform 0.2s, box-shadow 0.2s",
                  cursor: "pointer",
                  "&:hover": {
                    transform: "translateY(-5px)",
                    boxShadow: "0 6px 12px rgba(0,0,0,0.15)",
                  },
                }}
              >
                <div
                  style={{
                    fontSize: "1.3em",
                    fontWeight: "bold",
                    color: "#2185d0",
                  }}
                >
                  {credit.displayName}
                </div>

                <div
                  style={{
                    fontSize: "2.2em",
                    fontWeight: "bold",
                    color: "#21ba45", // Semantic UI green
                  }}
                >
                  ${credit.price.toFixed(2)}
                </div>

                <div
                  style={{
                    fontSize: "1.1em",
                    color: "#666",
                  }}
                >
                  {credit.credits} Credits
                </div>
              </div>
            </Grid.Column>
          ))}
        </Grid.Row>
      </Grid>
    </Container>
  );
};

export default PurchaseCredits;
