import React from "react";
import {
  Document,
  Page,
  Text,
  View,
  Image,
  StyleSheet,
} from "@react-pdf/renderer";

const QR_CODE_URL =
  process.env.NODE_ENV === "production"
    ? `${window.location.origin}/pb_qr.png`
    : "http://localhost:3000/pb_qr.png";

const styles = StyleSheet.create({
  page: {
    padding: 40,
    backgroundColor: "#ffffff",
    display: "flex",
    flexDirection: "column",
    minHeight: "100%",
  },
  contentContainer: {
    flex: 1,
    marginBottom: 150,
  },
  title: {
    fontSize: 24,
    textAlign: "center",
    marginBottom: 10,
    fontWeight: 900,
    color: "#000000",
  },
  subtitle: {
    fontSize: 14,
    textAlign: "center",
    marginBottom: 20,
    color: "#000000",
    fontStyle: "italic",
  },
  image: {
    width: "80%",
    marginHorizontal: "auto",
    marginVertical: 20,
    borderRadius: 5,
  },
  text: {
    fontSize: 12,
    lineHeight: 1.5,
    textAlign: "justify",
    marginTop: 20,
    color: "#000000",
  },
  footerContainer: {
    marginTop: "auto",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    paddingTop: 30,
  },
  footerText: {
    textAlign: "center",
    color: "#000000",
    fontSize: 10,
    marginBottom: 10,
  },
  footerUrl: {
    textAlign: "center",
    color: "#000000",
    fontSize: 15,
    fontWeight: 900,
    marginBottom: 10,
  },
  qrCode: {
    width: 100,
    height: 100,
    marginBottom: 5,
  },
});

const StoryPDF = ({ title, imageUrl, text, userDisplayName = "Anonymous" }) => (
  <Document>
    <Page size="A4" style={styles.page}>
      <View style={styles.contentContainer}>
        <Text style={styles.title}>{title}</Text>
        <Text style={styles.subtitle}>
          An AI PictureBook by {userDisplayName}
        </Text>
        {imageUrl && <Image style={styles.image} src={imageUrl} />}
        <Text style={styles.text}>{text}</Text>
      </View>

      <View style={styles.footerContainer}>
        <Image style={styles.qrCode} src={{ uri: QR_CODE_URL }} />
        <Text style={styles.footerText}>
          Create your own amazing illustrated stories at:
        </Text>
        <Text style={styles.footerUrl}>http://picturebooks.ai</Text>
      </View>
    </Page>
  </Document>
);

export default StoryPDF;
