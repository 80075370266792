import React, { useState, useEffect } from "react";
import { useNavigate, NavLink } from "react-router-dom";
import styles from "./styles/Navbar.module.css";
import { createAvatar } from "@dicebear/core";
import { adventurer } from "@dicebear/collection";
import { handleLogout } from "./helpers/handleLogout";
import { navbarMap, languageSelectionMap } from "./constants/siteTextMap";

const NavbarMobile = ({
  currentUser,
  imageApi,
  firestoreUserData,
  db,
  auth,
  setImageUrls,
  setFirestoreUserData,
}) => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isProfileMenuOpen, setProfileMenuOpen] = useState(false);
  const [language, setLanguage] = useState("English");
  const navigate = useNavigate();

  const { avatarSeed, userId, membership, currentLanguage, planType, credit } =
    firestoreUserData || {};

  useEffect(() => {
    if (firestoreUserData?.currentLanguage) {
      setLanguage(firestoreUserData.currentLanguage);
    }
  }, [firestoreUserData]);

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
    setProfileMenuOpen(false);
  };

  const toggleProfileMenu = () => {
    setProfileMenuOpen(!isProfileMenuOpen);
    setIsMenuOpen(false);
  };

  const avatar = createAvatar(adventurer, {
    seed: avatarSeed ? avatarSeed : userId,
  });
  const svgAvatar = avatar.toDataUriSync();

  const createUrl = process.env.REACT_APP_CREATE_STORY_ACTIVE;

  const handleSignOut = () => {
    handleLogout({ auth, setImageUrls, setFirestoreUserData, navigate });
    setIsMenuOpen(false);
    setProfileMenuOpen(false);
  };

  return (
    <nav className={styles.navbar}>
      <div className={styles.navbarHeader}>
        <img
          src={svgAvatar}
          alt="Avatar"
          className={styles.icon}
          onClick={toggleProfileMenu}
        />
        <button onClick={toggleMenu} className={styles.menuButton}>
          {isMenuOpen ? "✕" : "☰"}
        </button>
      </div>
      <div
        className={`${styles.navMenu} ${isProfileMenuOpen ? styles.open : ""}`}
      >
        <NavLink
          to="/profile"
          className={styles.navItem}
          onClick={toggleProfileMenu}
        >
          {navbarMap[language]?.profile || navbarMap["English"].profile}
        </NavLink>
        <NavLink
          to="/purchase-credits"
          className={styles.navItem}
          onClick={toggleProfileMenu}
        >
          {navbarMap[language]?.credits || navbarMap["English"].credits}:{" "}
          {credit}
        </NavLink>
        <NavLink
          to="/about"
          className={styles.navItem}
          onClick={toggleProfileMenu}
        >
          {navbarMap[language]?.about || navbarMap["English"].about}
        </NavLink>

        <div
          onClick={() => {
            handleLogout({
              auth,
              setImageUrls,
              setFirestoreUserData,
              navigate,
            });
            toggleProfileMenu();
          }}
          className={styles.navItem}
        >
          {navbarMap[language]?.logout || navbarMap["English"].logout}
        </div>
      </div>
      <div className={`${styles.navMenu} ${isMenuOpen ? styles.open : ""}`}>
        <NavLink to={createUrl} className={styles.navItem} onClick={toggleMenu}>
          {navbarMap[language]?.create || navbarMap["English"].create}
        </NavLink>
        <NavLink to="/stories" className={styles.navItem} onClick={toggleMenu}>
          {navbarMap[language]?.bookshelf || navbarMap["English"].bookshelf}
        </NavLink>
        {/* TEMP DISABLED: Premium adventures feature
          {planType === "premium" && (
            <NavLink
              to="/adventures"
              className={styles.navItem}
              onClick={toggleMenu}
            >
              {navbarMap[language]?.adventure || navbarMap["English"].adventure}
            </NavLink>
          )}
        */}
        <NavLink
          to="/language-selection"
          className={styles.navItem}
          onClick={toggleMenu}
        >
          <span role="img" aria-label="Language flag">
            {firestoreUserData?.currentFlag}
          </span>{" "}
          {languageSelectionMap[currentLanguage]
            ? languageSelectionMap[currentLanguage][
                currentLanguage.toLowerCase()
              ]
            : languageSelectionMap["English"][currentLanguage?.toLowerCase()]}
        </NavLink>
        <button className={styles.signOutButton} onClick={handleSignOut}>
          {navbarMap[language]?.logout || navbarMap["English"].logout}
        </button>
      </div>
    </nav>
  );
};

export default NavbarMobile;
