// saveNewStory.js

import { getStorage, ref, getDownloadURL } from "firebase/storage";
import { setDoc, doc, serverTimestamp } from "firebase/firestore";
import { db } from "../../firebase";

const storage = getStorage();

const saveNewStory = ({ currentUser, storyObject, setServerError }) => {
  return new Promise(async (resolve, reject) => {
    if (!currentUser) {
      reject(new Error("No current user"));
      return;
    }

    try {
      const storyObjectWithMeta = {
        ...storyObject,
        userId: currentUser.uid,
        userDisplayName: currentUser?.displayName,
        userEmail: currentUser?.email,
        dateCreated: serverTimestamp(),
        dateModified: serverTimestamp(),
        tokensUsed: { text: null, image: null },
        themeObject: {},
        languageEmoji: storyObject.languageEmoji || "🌎",
      };

      const { storyId } = storyObjectWithMeta;
      const suffixId = "_picturebook_DEC2022.png";
      const basePath = `images/USERSET_A_${currentUser.uid}/`;

      const imageFilePath = basePath + storyId + suffixId;
      const imageThumbFilePath = basePath + "thumb_" + storyId + suffixId;
      const imageMediumFilePath = basePath + "medium_" + storyId + suffixId;

      // ORIGINAL IMAGE
      storyObjectWithMeta.imageDownloadURL = await getDownloadURL(
        ref(storage, imageFilePath)
      );
      // THUMBNAIL
      storyObjectWithMeta.imageThumbDownloadURL = await getDownloadURL(
        ref(storage, imageThumbFilePath)
      );
      // MEDIUM
      storyObjectWithMeta.imageMediumDownloadURL = await getDownloadURL(
        ref(storage, imageMediumFilePath)
      );

      // console.log("🟡🟡🟡 storyObject", storyObject);
      // console.log("🟡🟡🟡 storyObjectWithMeta", storyObjectWithMeta);

      await setDoc(
        doc(db, "users", currentUser.uid, "stories", storyId),
        storyObjectWithMeta
      );

      // setSavingStory(false);
      // setSuccessfulSave(true);
      // setShowNavbar(true);
      resolve(); // Resolve the promise upon successful completion
    } catch (err) {
      // setSavingStory(false);
      setServerError(true);
      // setShowNavbar(true);
      console.error("❌", err.message, "❌");
      reject(err); // Reject the promise on error
    }
  });
};

export default saveNewStory;
