const themes = {
  christmasTheme: {
    title: "Christmas Stories!",
    multilingualTitle: {
      english: "Christmas Stories!",
      spanish: "Historias de Navidad!",
      french: "Histoires de Noël!",
      german: "Weihnachtsgeschichten!",
      italian: "Storie di Natale!",
      portuguese: "Histórias de Natal!",
      tagalog: "Mga Kuwento ng Pasko!",
      japanese: "クリスマスの話!",
      hindi: "क्रिसमस की कहानियाँ!",
      greek: "Χριστουγεννιάτικες Ιστορίες!",
    },
    robotImgUrl: "",
    buttonsPathPrefix: "button-images-christmas",
    style: { fontSize: "4em", color: "red", color2: "green" },
    descriptionForApi: "Christmas",
  },
  gratitudeTheme: {
    title: "Gratitude Stories",
    multilingualTitle: {
      english: "Gratitude Stories",
      spanish: "Historias de Gratitud",
      french: "Histoires de Gratitude",
      german: "Dankbarkeitsgeschichten",
      italian: "Storie di Gratitudine",
      portuguese: "Histórias de Gratidão",
      tagalog: "Mga Kuwento ng Pasasalamat",
      japanese: "感謝の話",
      hindi: "कृतज्ञता की कहानियाँ",
      greek: "Ιστορίες Ευγνωμοσύνης",
    },
    robotImgUrl: "",
    buttonsPathPrefix: "button-images",
    style: { fontSize: "4em", color: "red", color2: "orange" },
    descriptionForApi: "Gratitude",
  },
  newYearsTheme: {
    title: "New Year's Stories",
    multilingualTitle: {
      english: "New Year's Stories",
      spanish: "Historias de Año Nuevo",
      french: "Histoires du Nouvel An",
      german: "Neujahrsgeschichten",
      italian: "Storie di Capodanno",
      portuguese: "Histórias de Ano Novo",
      tagalog: "Mga Kuwento ng Bagong Taon",
      japanese: "新年の話",
      hindi: "नए साल की कहानियाँ",
      greek: "Πρωτοχρονιάτικες Ιστορίες",
    },
    robotImgUrl: "",
    buttonsPathPrefix: "button-images",
    style: { fontSize: "4em", color: "blue", color2: "yellow" },
    descriptionForApi: "NewYears",
  },
  valentinesTheme: {
    title: "Valentine's Stories",
    multilingualTitle: {
      english: "Valentine's Stories",
      spanish: "Historias de San Valentín",
      french: "Histoires de la Saint-Valentin",
      german: "Valentinstagsgeschichten",
      italian: "Storie di San Valentino",
      portuguese: "Histórias de Dia dos Namorados",
      tagalog: "Mga Kuwento ng Araw ng mga Puso",
      japanese: "バレンタインデーの話",
      hindi: "वेलेंटाइन डे की कहानियाँ",
      greek: "Ιστορίες για τον Άγιο Βαλεντίνο",
    },
    robotImgUrl: "",
    buttonsPathPrefix: "button-images",
    style: { fontSize: "4em", color: "red", color2: "pink" },
    descriptionForApi: "Valentines",
  },
  stPatricksTheme: {
    title: "St. Patrick's Stories",
    multilingualTitle: {
      english: "St. Patrick's Stories",
      spanish: "Historias de San Patricio",
      french: "Histoires de la Saint-Patrick",
      german: "St. Patrick's Geschichten",
      italian: "Storie di San Patrizio",
      portuguese: "Histórias de São Patrício",
      tagalog: "Mga Kuwento ni San Patricio",
      japanese: "セントパトリックの話",
      hindi: "सेंट पैट्रिक की कहानियाँ",
      greek: "Ιστορίες του Αγίου Πατρίκιου",
    },
    robotImgUrl: "",
    buttonsPathPrefix: "button-images",
    style: { fontSize: "4em", color: "green", color2: "darkgreen" },
    descriptionForApi: "StPatricks",
  },
  easterTheme: {
    title: "Easter Stories",
    multilingualTitle: {
      english: "Easter Stories",
      spanish: "Historias de Pascua",
      french: "Histoires de Pâques",
      german: "Ostergeschichten",
      italian: "Storie di Pasqua",
      portuguese: "Histórias de Páscoa",
      tagalog: "Mga Kuwento ng Pasko ng Pagkabuhay",
      japanese: "イースターの話",
      hindi: "ईस्टर की कहानियाँ",
      greek: "Πασχαλινές Ιστορίες",
    },
    robotImgUrl: "",
    buttonsPathPrefix: "button-images",
    style: { fontSize: "4em", color: "pink", color2: "lightblue" },
    descriptionForApi: "Easter",
  },
  halloweenTheme: {
    title: "Halloween Stories",
    multilingualTitle: {
      english: "Halloween Stories",
      spanish: "Historias de Halloween",
      french: "Histoires d'Halloween",
      german: "Halloween-Geschichten",
      italian: "Storie di Halloween",
      portuguese: "Histórias de Halloween",
      tagalog: "Mga Kuwento ng Halloween",
      japanese: "ハロウィンの話",
      hindi: "हैलोवीन की कहानियाँ",
      greek: "Χαλοουίν Ιστορίες",
    },
    robotImgUrl: "",
    buttonsPathPrefix: "button-images",
    style: { fontSize: "4em", color: "orange", color2: "black" },
    descriptionForApi: "Halloween",
  },
  summerTheme: {
    title: "Summer Stories",
    multilingualTitle: {
      english: "Summer Stories",
      spanish: "Historias de Verano",
      french: "Histoires d'Été",
      german: "Sommerngeschichten",
      italian: "Storie d'Estate",
      portuguese: "Histórias de Verão",
      tagalog: "Mga Kuwento ng Tag-init",
      japanese: "夏の話",
      hindi: "गर्मी की कहानियाँ",
      greek: "Καλοκαιρινές Ιστορίες",
    },
    robotImgUrl: "",
    buttonsPathPrefix: "button-images",
    style: { fontSize: "4em", color: "blue", color2: "yellow" },
    descriptionForApi: "Summer",
  },
  autumnTheme: {
    title: "Autumn Stories",
    multilingualTitle: {
      english: "Autumn Stories",
      spanish: "Historias de Otoño",
      french: "Histoires d'Automne",
      german: "Herbstgeschichten",
      italian: "Storie di Autunno",
      portuguese: "Histórias de Outono",
      tagalog: "Mga Kuwento ng Taglagas",
      japanese: "秋の話",
      hindi: "पतझड़ की कहानियाँ",
      greek: "Φθινοπωρινές Ιστορίες",
    },
    robotImgUrl: "",
    buttonsPathPrefix: "button-images",
    style: { fontSize: "4em", color: "red", color2: "orange" },
    descriptionForApi: "Autumn",
  },
  // Adding to the themes object
  winterTheme: {
    title: "Winter Stories",
    multilingualTitle: {
      english: "Winter Stories",
      spanish: "Historias de Invierno",
      french: "Histoires d'Hiver",
      german: "Wintergeschichten",
      italian: "Storie di Inverno",
      portuguese: "Histórias de Inverno",
      tagalog: "Mga Kuwento ng Taglamig",
      japanese: "冬の話",
      hindi: "सर्दी की कहानियाँ",
      greek: "Χειμωνιάτικες Ιστορίες",
    },
    robotImgUrl: "",
    buttonsPathPrefix: "button-images",
    style: { fontSize: "4em", color: "darkgray", color2: "black" },
    descriptionForApi: "Winter",
  },

  springTheme: {
    title: "Spring Stories",
    multilingualTitle: {
      english: "Spring Stories",
      spanish: "Historias de Primavera",
      french: "Histoires de Printemps",
      german: "Frühlingsgeschichten",
      italian: "Storie di Primavera",
      portuguese: "Histórias de Primavera",
      tagalog: "Mga Kuwento ng Tagsibol",
      japanese: "春の話",
      hindi: "वसंत की कहानियाँ",
      greek: "Ανοιξιάτικες Ιστορίες",
    },
    robotImgUrl: "",
    buttonsPathPrefix: "button-images",
    style: { fontSize: "4em", color: "green", color2: "yellow" },
    descriptionForApi: "Spring",
  },

  defaultTheme: {
    title: "",
    robotImgUrl: "",
    buttonsPathPrefix: "button-images",
    style: { fontSize: "4em", color: "green", color2: "blue" },
    descriptionForApi: "default",
  },
};

export default themes;
