export const formatFirestoreTimestamp = (timestamp) => {
  if (!timestamp) return "";

  try {
    const date = timestamp.toDate();
    return date.toLocaleDateString("en-US", {
      year: "numeric",
      month: "short",
      day: "numeric",
    });
  } catch (error) {
    console.error("Error formatting timestamp:", error);
    return "";
  }
};
