// handleLogoutPromise.js

import { signOut } from "firebase/auth";
import { auth } from "../firebase";

export const handleLogoutPromise = ({ setFirestoreUserData }) => {
  return new Promise((resolve, reject) => {
    signOut(auth)
      .then(() => {
        setFirestoreUserData(null);
        resolve();
      })
      .catch((err) => {
        console.error(err.message);
        reject(err);
      });
  });
};
