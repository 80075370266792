import React from "react";
import { Icon, Button } from "semantic-ui-react";
import { PDFDownloadLink } from "@react-pdf/renderer";
import StoryPDF from "../../pdf/StoryPDF";

const IconWithLabel = ({ icon, color, onClick, label }) => (
  <div
    style={{
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      margin: "0 10px",
    }}
  >
    <Button icon onClick={onClick} color={color}>
      <Icon name={icon} />
    </Button>
    <span
      style={{
        fontSize: "0.8em",
        marginTop: "5px",
        color: "#666",
        textAlign: "center",
      }}
    >
      {label}
    </span>
  </div>
);

export const OptionsBarBasic = ({
  data,
  toggleFavorited,
  handleMetaDataDisplay,
  editStory,
  metaDataDisplayColor,
  handleBasicAiFeatures,
  basicAiFeaturesIconColor,
  handleShareStory,
  shareStoryIconColor,
  title,
  text,
  getImageSource,
  imageDownloadURL,
  imageMediumDownloadURL,
}) => {
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        margin: "20px 0",
      }}
    >
      <IconWithLabel
        icon="heart"
        color={data?.favorited ? "red" : "grey"}
        onClick={toggleFavorited}
        label="Favorite"
      />
      <IconWithLabel
        icon="edit"
        color="grey"
        onClick={editStory}
        label="Edit Story"
      />
      <IconWithLabel
        icon="info"
        color={metaDataDisplayColor}
        onClick={handleMetaDataDisplay}
        label="Meta Data"
      />
      <IconWithLabel
        icon="magic"
        color={basicAiFeaturesIconColor}
        onClick={handleBasicAiFeatures}
        label="AI Features"
      />
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          margin: "0 10px",
        }}
      >
        <PDFDownloadLink
          document={
            <StoryPDF
              title={title}
              imageUrl={getImageSource({
                imageDownloadURL,
                imageMediumDownloadURL,
              })}
              text={text}
              userDisplayName={data?.userDisplayName}
            />
          }
          fileName={`${title.toLowerCase().replace(/\s+/g, "-")}.pdf`}
        >
          {({ blob, url, loading, error }) => (
            <Button
              icon
              style={{ transition: "all 0.3s" }}
              onClick={(e) =>
                (e.currentTarget.style.backgroundColor = "#db2828")
              }
              color="grey"
              disabled={loading}
            >
              <Icon name="file pdf outline" />
            </Button>
          )}
        </PDFDownloadLink>
        <span
          style={{
            fontSize: "0.8em",
            marginTop: "5px",
            color: "#666",
            textAlign: "center",
          }}
        >
          PDF
        </span>
      </div>
      <IconWithLabel
        icon="share"
        color={shareStoryIconColor}
        onClick={handleShareStory}
        label="Export"
      />
    </div>
  );
};

// Premium version with additional features
export const OptionsBarPremium = (props) => {
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        margin: "20px 0",
      }}
    >
      <IconWithLabel
        icon="heart"
        color={props.data?.favorited ? "red" : "grey"}
        onClick={props.toggleFavorited}
        label="Favorite"
      />
      <IconWithLabel
        icon="edit"
        color="grey"
        onClick={props.editStory}
        label="Edit Story"
      />
      <IconWithLabel
        icon="info"
        color={props.metaDataDisplayColor}
        onClick={props.handleMetaDataDisplay}
        label="Meta Data"
      />
      <IconWithLabel
        icon="magic"
        color={props.basicAiFeaturesIconColor}
        onClick={props.handleBasicAiFeatures}
        label="Premium AI"
      />
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          margin: "0 10px",
        }}
      >
        <PDFDownloadLink
          document={
            <StoryPDF
              title={props.title}
              imageUrl={props.getImageSource({
                imageDownloadURL: props.imageDownloadURL,
                imageMediumDownloadURL: props.imageMediumDownloadURL,
              })}
              text={props.text}
              userDisplayName={props.data?.userDisplayName}
            />
          }
          fileName={`${props.title.toLowerCase().replace(/\s+/g, "-")}.pdf`}
        >
          {({ blob, url, loading, error }) => (
            <Button
              icon
              style={{ transition: "all 0.3s" }}
              onClick={(e) =>
                (e.currentTarget.style.backgroundColor = "#db2828")
              }
              color="grey"
              disabled={loading}
            >
              <Icon name="file pdf outline" />
            </Button>
          )}
        </PDFDownloadLink>
        <span
          style={{
            fontSize: "0.8em",
            marginTop: "5px",
            color: "#666",
            textAlign: "center",
          }}
        >
          PDF
        </span>
      </div>
      <IconWithLabel
        icon="share"
        color={props.shareStoryIconColor}
        onClick={props.handleShareStory}
        label="Export"
      />
      <IconWithLabel
        icon="lab"
        color="yellow"
        onClick={props.handleAdvancedFeatures}
        label="Advanced"
      />
    </div>
  );
};

// Free version with limited features
export const OptionsBarFree = (props) => {
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        margin: "20px 0",
      }}
    >
      <IconWithLabel
        icon="heart"
        color={props.data?.favorited ? "red" : "grey"}
        onClick={props.toggleFavorited}
        label="Favorite"
      />
      <IconWithLabel
        icon="edit"
        color="grey"
        onClick={props.editStory}
        label="Edit Story"
      />
      <IconWithLabel
        icon="info"
        color={props.metaDataDisplayColor}
        onClick={props.handleMetaDataDisplay}
        label="Meta Data"
      />
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          margin: "0 10px",
        }}
      >
        <PDFDownloadLink
          document={
            <StoryPDF
              title={props.title}
              imageUrl={props.getImageSource({
                imageDownloadURL: props.imageDownloadURL,
                imageMediumDownloadURL: props.imageMediumDownloadURL,
              })}
              text={props.text}
              userDisplayName={props.data?.userDisplayName}
            />
          }
          fileName={`${props.title.toLowerCase().replace(/\s+/g, "-")}.pdf`}
        >
          {({ blob, url, loading, error }) => (
            <Button
              icon
              style={{ transition: "all 0.3s" }}
              onClick={(e) =>
                (e.currentTarget.style.backgroundColor = "#db2828")
              }
              color="grey"
              disabled={loading}
            >
              <Icon name="file pdf outline" />
            </Button>
          )}
        </PDFDownloadLink>
        <span
          style={{
            fontSize: "0.8em",
            marginTop: "5px",
            color: "#666",
            textAlign: "center",
          }}
        >
          PDF
        </span>
      </div>
      <IconWithLabel
        icon="share"
        color={props.shareStoryIconColor}
        onClick={props.handleShareStory}
        label="Export"
      />
    </div>
  );
};
