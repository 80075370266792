// NewUserSetup.jsx
import React, { useEffect, useState } from "react";
import { Button, Container, Modal, Dropdown } from "semantic-ui-react";
import { useNavigate } from "react-router-dom";
import updateUserPromise from "../api/updateUserPromise";
import { createAvatar } from "@dicebear/core";
import { adventurer } from "@dicebear/collection";
import { randomNum } from "../../helpers/randomNumber";
import updateUser from "../api/updateUser";
import Introduction from "./NewUserIntro";

import { WORLD_COUNTRIES_ALL } from "../../constants/world-countries-all";
import { newUserSetupMap } from "../../constants/siteTextMap";

function NewUserSetup(props) {
  const navigate = useNavigate();

  const { db, firestoreUserData, setShowNavbar, currentUser } = props || {};

  const uid = props && props.currentUser ? props.currentUser.uid : null;

  const {
    userDisplayName,
    email,
    avatarSeed,
    credit,
    userId,
    currentLanguage,
  } = firestoreUserData || {};

  const [edittedAvatar, setEdittedAvatar] = useState(null);
  const [selectedLanguage, setSelectedLanguage] = useState("");
  const [modalOpen, setModalOpen] = useState(false);
  const [modalInput, setModalInput] = useState(userDisplayName);
  const [language, setLanguage] = useState("English");

  console.log("WORLD_COUNTRIES_ALL", WORLD_COUNTRIES_ALL);

  const getFlagEmoji = (language) => {
    const countryInfo = WORLD_COUNTRIES_ALL[0].find(
      (country) => country.primaryLanguageSpoken === language
    );
    return countryInfo ? countryInfo.emoji : "";
  };

  const setLanguageAndUpdateDb = async (newLanguage) => {
    setLanguage(newLanguage); // Update local state

    // Update Firebase
    await updateUserPromise({
      userId: uid, // Assuming you already have this from your code
      updatedFields: { currentLanguage: newLanguage },
    });
  };

  console.log("firestoreUserData", firestoreUserData);

  const getUniqueLanguages = () => {
    const allLanguages = WORLD_COUNTRIES_ALL[0].map(
      (country) => country.primaryLanguageSpoken
    );
    const uniqueLanguages = [...new Set(allLanguages)];
    return uniqueLanguages;
  };

  const languageOptions = getUniqueLanguages();

  const languageDropdownOptions = languageOptions.map((lang, index) => ({
    key: index,
    text: lang,
    value: lang,
    content: (
      <>
        {getFlagEmoji(lang)} {lang}
      </>
    ),
  }));

  const handleAvatarClick = () => {
    const seed = avatarSeed ? avatarSeed : userId;
    const newSeed = seed + randomNum(10000);
    setEdittedAvatar(() => newSeed);
  };

  const discardAvatar = () => {
    setEdittedAvatar(() => null);
  };
  const saveAvatar = ({ userId, db }) => {
    updateUser({ userId, db, updatedFields: { avatarSeed: edittedAvatar } });
    setEdittedAvatar(() => null);
  };

  useEffect(() => {
    setShowNavbar(false);
  }, []);
  useEffect(() => {
    if (!uid) {
      navigate("/login");
    }
  }, [uid]);

  useEffect(() => {
    setSelectedLanguage(firestoreUserData?.currentLanguage || "English");
  }, [firestoreUserData]);

  const finalizeSetup = async () => {
    await updateUserPromise({
      userId: uid,
      updatedFields: { newUser: false },
    });
    navigate("/home");
  };

  const avatar = createAvatar(adventurer, {
    seed: avatarSeed ? avatarSeed : userId,
  });
  const svgAvatar = avatar.toDataUriSync();
  const avatarUpdated = createAvatar(adventurer, {
    seed: edittedAvatar ? edittedAvatar : "abc",
  });
  const svgAvatarUpdated = avatarUpdated.toDataUriSync();

  const handleEditDisplayNameOpenModal = () => {
    setModalInput(userDisplayName);
    setModalOpen(true);
  };

  const handleCloseModal = () => {
    setModalOpen(false);
  };

  const handleInputChange = (e) => {
    setModalInput(e.target.value);
  };

  const handleSaveClick = () => {
    updateUser({ userId, db, updatedFields: { userDisplayName: modalInput } });
    setModalOpen(false);
  };

  const getButtonText = (buttonText) => {
    let translatedText;
    try {
      translatedText =
        newUserSetupMap[language][buttonText] ||
        newUserSetupMap["English"][buttonText];
      return translatedText;
    } catch (err) {
      translatedText = newUserSetupMap["English"][buttonText];
      return translatedText;
    }
  };

  // LATER: For step by step intro
  // if (!userDisplayName) return <Introduction />

  return (
    <Container>
      <br />
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <div style={{ width: "100%", textAlign: "center" }}>
          <h2 style={{ cursor: "default", margin: "0" }}>
            {newUserSetupMap[language]?.welcome ||
              newUserSetupMap["English"].welcome}{" "}
            {userDisplayName}!
          </h2>
        </div>
        <div
          style={{
            width: "50%",
            position: "absolute",
            left: 0,
            marginLeft: "0.5in",
          }}
        >
          {/* <Dropdown
          text={`${getFlagEmoji(language)} ${language}`}
          options={languageDropdownOptions}
          onChange={(e, { value }) => setLanguage(value)}
        /> */}

          <Dropdown
            text={`${getFlagEmoji(language)} ${language}`}
            options={languageDropdownOptions}
            onChange={(e, { value }) => setLanguageAndUpdateDb(value)}
          />
        </div>
      </div>

      <Modal
        open={modalOpen}
        onClose={handleCloseModal}
        size="tiny"
        className="custom-modal centered-modal"
        style={{
          position: "fixed",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          height: "auto",
        }}
      >
        <Modal.Content>
          <div style={{ textAlign: "center" }}>
            <div style={{ fontSize: "1.5em", marginBottom: "10px" }}>
              Edit Display Name
            </div>
            <textarea
              className="modal-textarea"
              value={modalInput}
              onChange={handleInputChange}
              style={{
                fontSize: "2em",
                width: "100%",
                height: "50px",
                resize: "none",
                padding: "5px",
              }}
            />
          </div>
        </Modal.Content>

        <Modal.Actions style={{ display: "flex", justifyContent: "center" }}>
          <Button color="green" onClick={handleSaveClick}>
            Save
          </Button>
          <Button color="black" onClick={handleCloseModal}>
            Cancel
          </Button>
        </Modal.Actions>
      </Modal>
      {edittedAvatar ? (
        <>
          <div className="center" onClick={handleAvatarClick}>
            {svgAvatar && (
              <img src={svgAvatarUpdated} alt="" height="190px" width="190px" />
            )}
          </div>
          <br />
          <div className="center">
            <Modal.Actions
              style={{ display: "flex", justifyContent: "center" }}
            >
              <Button
                color="green"
                size="mini"
                onClick={() => saveAvatar({ userId, db })}
              >
                {getButtonText("keep")}
              </Button>
              <Button color="grey" size="mini" onClick={discardAvatar}>
                {getButtonText("toss")}
              </Button>
            </Modal.Actions>
            <br />
          </div>
        </>
      ) : (
        <>
          <div
            className="center"
            style={{ cursor: "pointer" }}
            onClick={handleAvatarClick}
          >
            {svgAvatar && (
              <img src={svgAvatar} alt="" height="200px" width="200px" />
            )}
          </div>
        </>
      )}

      <h3 className="center">
        {newUserSetupMap[language]?.part_1 || newUserSetupMap["English"].part_1}
      </h3>
      <br />
      <h3 className="center">
        {newUserSetupMap[language]?.part_2 || newUserSetupMap["English"].part_2}
      </h3>
      <br />
      <h3 className="center">
        {newUserSetupMap[language]?.part_3 || newUserSetupMap["English"].part_3}
      </h3>

      <br />

      <div className="center">
        <Button size="huge" color="green" onClick={finalizeSetup}>
          {getButtonText("begin")}
        </Button>
      </div>
    </Container>
  );
}

export default NewUserSetup;
