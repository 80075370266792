// PDFGenerator.js

import React, { useState, useEffect } from "react";
import { getStorage, ref, getDownloadURL } from "firebase/storage";
import { app } from "../../firebase"; // Adjust the path as needed
import { Icon } from "semantic-ui-react";

import {
  Document,
  Page,
  Text,
  Image,
  PDFDownloadLink,
  StyleSheet,
  View,
} from "@react-pdf/renderer";

const styles = StyleSheet.create({
  page: {
    flexDirection: "column",
    backgroundColor: "#FFFFFF",
    padding: 30,
  },
  title: {
    fontSize: 24,
    marginBottom: 20,
    fontWeight: "bold",
    textAlign: "center",
  },
  imageContainer: {
    width: 300,
    height: 300,
    marginBottom: 20,
    alignSelf: "center",
  },
  image: {
    objectFit: "contain",
    width: "100%",
    height: "100%",
  },
  storyText: {
    fontSize: 12,
    marginBottom: 20,
  },
  qrCodeContainer: {
    width: 100,
    height: 100,
    alignSelf: "center",
    marginTop: "auto", // This will push the QR code to the bottom
  },
  qrCode: {
    objectFit: "contain",
    width: "100%",
    height: "100%",
  },
});

const MyDocument = ({ title, storyText, imageData, qrCodeImage }) => (
  <Document>
    <Page size="A4" style={styles.page}>
      <Text style={styles.title}>{title}</Text>
      {imageData && (
        <View style={styles.imageContainer}>
          <Image style={styles.image} src={imageData} />
        </View>
      )}
      <Text style={styles.storyText}>{storyText}</Text>
      {qrCodeImage && (
        <View style={styles.qrCodeContainer}>
          <Image style={styles.qrCode} src={qrCodeImage} />
        </View>
      )}
    </Page>
  </Document>
);

const PDFGenerator = ({
  title,
  storyText,
  imageUrl,
  qrCodeData,
  generateQRCode,
  currentUser,
  baseUrl,
  storyId,
  quizArray,
  updateStory,
  db,
  setGeneratedStaticLink,
  setLinkCopied,
}) => {
  const [isClient, setIsClient] = useState(false);
  const [imageData, setImageData] = useState(null);
  const [qrCodeImage, setQrCodeImage] = useState(null);

  useEffect(() => {
    setIsClient(true);
  }, []);

  useEffect(() => {
    const getQRCode = async () => {
      try {
        if (qrCodeData) {
          // If qrCodeData is already a data URL, use it directly
          setQrCodeImage(qrCodeData);
        } else {
          // Generate new QR code
          const newQRCode = await generateQRCode({
            title,
            text: storyText,
            quizArray,
            imageDownloadURL: imageUrl,
            currentUser,
            baseUrl,
            storyId,
            updateStory,
            db,
            setGeneratedStaticLink,
            setLinkCopied,
          });

          // Ensure newQRCode is a data URL
          if (
            newQRCode &&
            typeof newQRCode === "string" &&
            newQRCode.startsWith("data:")
          ) {
            setQrCodeImage(newQRCode);
          } else {
            console.error("Invalid QR code data:", newQRCode);
          }
        }
      } catch (error) {
        console.error("Error generating QR code:", error);
      }
    };

    getQRCode();
  }, [
    qrCodeData,
    generateQRCode,
    title,
    storyText,
    quizArray,
    imageUrl,
    currentUser,
    baseUrl,
    storyId,
    updateStory,
    db,
    setGeneratedStaticLink,
    setLinkCopied,
  ]);

  useEffect(() => {
    const fetchImage = async () => {
      if (imageUrl) {
        try {
          const storage = getStorage(app);
          const imageRef = ref(storage, imageUrl);
          const url = await getDownloadURL(imageRef);
          const response = await fetch(url, { mode: "cors" });
          if (!response.ok) {
            throw new Error("Network response was not ok");
          }
          const blob = await response.blob();
          const reader = new FileReader();
          reader.onloadend = () => setImageData(reader.result);
          reader.readAsDataURL(blob);
        } catch (error) {
          console.error("Error fetching image:", error);
        }
      }
    };

    fetchImage();
  }, [imageUrl]);

  if (!isClient) {
    return null;
  }

  return (
    <div>
      {imageUrl ? (
        <PDFDownloadLink
          document={
            <MyDocument
              storyText={storyText}
              title={title}
              imageData={imageData}
              qrCodeImage={qrCodeImage}
            />
          }
          fileName="generated_document.pdf"
        >
          {({ blob, url, loading, error }) =>
            loading ? (
              "Loading document..."
            ) : (
              <Icon
                name={"file pdf"}
                //color={pdfIconColor}
                color={"orange"}
                style={{
                  fontSize: "4em",
                  cursor: "pointer",
                }}
                // onClick={handlePDF}
              />
            )
          }
        </PDFDownloadLink>
      ) : (
        "Waiting for image URL..."
      )}
    </div>
  );
};
export default PDFGenerator;
