// stripe/PurchaseOptions.jsx

import React from "react";
import { useNavigate } from "react-router-dom";
import { Container, Button } from "semantic-ui-react";

const PurchaseOptions = () => {
  let navigate = useNavigate();

  const goToBuyCredits = () => {
    navigate("/purchase-credits");
  };

  const goToSubscription = () => {
    navigate("/subscription");
  };

  return (
    <Container>
      <h2 className="center">Purchase Options</h2>
      <h3>
        In order to continue to create amazing stories, you have two choices:
      </h3>
      <br />
      <Button onClick={goToBuyCredits}>Buy Credits</Button>
      <Button onClick={goToSubscription}>Subscribe</Button>
    </Container>
  );
};

export default PurchaseOptions;
