// stripe/Subscriptions.jsx - FRONT END

import React, { useState, useEffect } from "react";
import {
  Button,
  Container,
  Grid,
  Segment,
  Divider,
  Modal,
  Checkbox,
} from "semantic-ui-react";
import { useAuthState } from "react-firebase-hooks/auth";
import { auth, db } from "../../firebase";
import { loadStripe } from "@stripe/stripe-js";
import toast, { Toaster } from "react-hot-toast";
import axios from "axios";
import { doc, getDoc, updateDoc } from "firebase/firestore";
import { useNavigate } from "react-router-dom";

const baseUrl = process.env.REACT_APP_API_URL;

const PLAN_PRICES = {
  basic: { monthly: 15, yearly: 95 },
  premium: { monthly: 25, yearly: 145 },
};

const Subscriptions = () => {
  const [user, loading] = useAuthState(auth);
  const [isYearly, setIsYearly] = useState(false);
  const [currentSubscription, setCurrentSubscription] = useState(null);
  const [modalOpen, setModalOpen] = useState(false);
  const [supportModalOpen, setSupportModalOpen] = useState(false);
  const [cancelModalOpen, setCancelModalOpen] = useState(false);
  const [switchPlan, setSwitchPlan] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    fetchSubscription();
  }, [user]);

  const fetchSubscription = async () => {
    if (user) {
      try {
        const userDoc = await getDoc(doc(db, "users", user.uid));
        if (userDoc.exists()) {
          setCurrentSubscription(userDoc.data().currentSubscription);
        }
      } catch (error) {
        console.error("Error fetching user subscription:", error);
      }
    }
  };

  const showToastAndNavigate = (type, message) => {
    const toastOptions = {
      duration: 3000,
      style: {
        background: type === "success" ? "#4CAF50" : "#F44336",
        color: "#FFFFFF",
        fontSize: "16px",
        padding: "15px 20px",
        borderRadius: "8px",
        boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
      },
    };

    if (type === "success") {
      toast.success(message, toastOptions);
    } else {
      toast.error(message, toastOptions);
    }

    setTimeout(() => navigate("/profile"), 3000);
  };

  const handleSubscribe = async (planName) => {
    if (loading) {
      showToastAndNavigate(
        "error",
        "Please wait while we check your authentication status"
      );
      return;
    }

    if (!user) {
      showToastAndNavigate("error", "Please log in to subscribe");
      return;
    }

    const stripePromise = loadStripe(
      process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY
    );

    const stripe = await stripePromise;
    if (!stripe) {
      showToastAndNavigate("error", "Failed to load Stripe");
      return;
    }

    try {
      const planId = `${planName}_${isYearly ? "yearly" : "monthly"}`;

      const response = await axios.post(
        `${baseUrl}/api/checkout_subscription`,
        {
          planId,
          userId: user.uid,
          email: user.email,
        }
      );

      const data = response.data;

      if (data.reactivated) {
        // Subscription was reactivated
        showToastAndNavigate(
          "success",
          "Your subscription has been reactivated successfully!"
        );
        // Update the local state with the latest subscription data
        fetchSubscription();
      } else if (data.id) {
        // New subscription needs to be created
        if (data.startDate) {
          // This is a future subscription
          showToastAndNavigate(
            "success",
            `Your new plan will start on ${new Date(
              data.startDate
            ).toLocaleDateString()}`
          );
        } else {
          const result = await stripe.redirectToCheckout({
            sessionId: data.id,
          });
          if (result.error) {
            showToastAndNavigate("error", result.error.message);
          }
        }
      } else {
        showToastAndNavigate("error", "Failed to process subscription request");
      }
    } catch (error) {
      console.error("Error processing subscription:", error);
      showToastAndNavigate(
        "error",
        "An error occurred while processing your subscription request"
      );
    }
  };

  const handleSwitchPlan = (planName) => {
    if (
      (currentSubscription.planId.startsWith("basic") &&
        planName === "Premium") ||
      (currentSubscription.planId.startsWith("premium") && planName === "Basic")
    ) {
      setSupportModalOpen(true);
    } else {
      setSwitchPlan(planName);
      setModalOpen(true);
    }
  };

  const confirmSwitchPlan = () => {
    setModalOpen(false);
    handleSubscribe(switchPlan.toLowerCase());
  };

  const handleCancelSubscription = async () => {
    if (!user || !currentSubscription) return;

    try {
      await axios.post(
        `${baseUrl}/api/cancel_subscription`,
        {
          subscriptionId: currentSubscription.id,
          userId: user.uid,
        },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      await updateDoc(doc(db, "users", user.uid), {
        "currentSubscription.cancelAtPeriodEnd": true,
      });

      setCurrentSubscription((prev) => ({ ...prev, cancelAtPeriodEnd: true }));
      setCancelModalOpen(false);
      showToastAndNavigate(
        "error",
        "Your subscription has been cancelled. Your subscription will remain active until the end of the current billing period."
      );
    } catch (error) {
      console.error("Error cancelling subscription:", error);
      showToastAndNavigate(
        "error",
        "An error occurred while scheduling the subscription cancellation."
      );
    }
  };

  const PlanCard = ({ planName, price, features }) => {
    const isSubscribedToCurrentPlan =
      currentSubscription &&
      currentSubscription.planId.startsWith(planName.toLowerCase());
    const switchToPlan = planName === "Basic" ? "Premium" : "Basic";
    const isSwitching = currentSubscription && !isSubscribedToCurrentPlan;

    const isCancelled =
      currentSubscription && currentSubscription.cancelAtPeriodEnd;

    return (
      <Segment>
        <h5>
          {isSubscribedToCurrentPlan
            ? `Current Subscription: ${planName}`
            : `${planName} Plan`}
        </h5>
        {!currentSubscription && (
          <p>
            ${price} <span>{isYearly ? "yearly" : "billed monthly"}</span>
          </p>
        )}
        <ul>
          {features.map((feature, index) => (
            <li key={index}>{feature}</li>
          ))}
        </ul>
        {isSubscribedToCurrentPlan ? (
          isCancelled ? (
            <Button
              color="green"
              onClick={() => handleSubscribe(planName.toLowerCase())}
            >
              Subscribe Again
            </Button>
          ) : (
            <Button color="red" onClick={() => setCancelModalOpen(true)}>
              Cancel Subscription
            </Button>
          )
        ) : (
          <>
            {!isSwitching && (
              <Button
                color={planName.toLowerCase() === "premium" ? "blue" : "yellow"}
                onClick={() => handleSubscribe(planName.toLowerCase())}
              >
                Subscribe to {planName}
              </Button>
            )}
            {isSwitching && (
              <Button
                color={
                  switchToPlan.toLowerCase() === "premium" ? "blue" : "yellow"
                }
                onClick={() => handleSwitchPlan(planName)}
              >
                Switch to {planName}
              </Button>
            )}
          </>
        )}
      </Segment>
    );
  };

  let newSubscriptionStartDate = null;
  if (currentSubscription) {
    newSubscriptionStartDate = new Date(
      currentSubscription.currentPeriodEnd.seconds * 1000
    );
    newSubscriptionStartDate.setDate(newSubscriptionStartDate.getDate() + 1);
  }

  return (
    <Container>
      <Toaster position="top-center" reverseOrder={false} />
      <h2 className="center">Subscription Options:</h2>
      <Grid columns={2} divided>
        <Grid.Row>
          <Grid.Column>
            <PlanCard
              planName="Basic"
              price={PLAN_PRICES.basic[isYearly ? "yearly" : "monthly"]}
              features={[
                "Create stories in 25+ languages",
                "Teacher features for comprehension and vocabulary",
                "Export to PDF to print",
                "200 stories a month",
              ]}
            />
          </Grid.Column>
          <Grid.Column>
            <PlanCard
              planName="Premium"
              price={PLAN_PRICES.premium[isYearly ? "yearly" : "monthly"]}
              features={[
                "ALL the basic features plus...",
                "Create series of stories",
                "Adjust the reading levels",
                "Chat with the characters",
                "Unlimited stories",
              ]}
            />
          </Grid.Column>
        </Grid.Row>
      </Grid>
      <Divider />
      {!currentSubscription && (
        <div className="billing-toggle center">
          <Checkbox
            toggle
            label={`Switch to ${isYearly ? "Monthly" : "Yearly"} Billing`}
            checked={isYearly}
            onChange={() => setIsYearly(!isYearly)}
          />
        </div>
      )}
      <Modal open={modalOpen} onClose={() => setModalOpen(false)}>
        <Modal.Header>Confirm Plan Switch</Modal.Header>
        <Modal.Content>
          <p>
            Your current <b>{currentSubscription?.displayName}</b> will remain
            active until{" "}
            {new Date(
              currentSubscription?.currentPeriodEnd.seconds * 1000
            ).toLocaleDateString()}
            .
          </p>
          {newSubscriptionStartDate && (
            <p>
              Your new <b>{switchPlan} Subscription</b> will begin immediately
              after your current subscription ends on{" "}
              {newSubscriptionStartDate.toLocaleDateString()}
              and be billed {isYearly ? "yearly" : "monthly"}. There is no
              additional charge today, and you can cancel at any time before the
              new subscription starts.
            </p>
          )}
        </Modal.Content>
        <Modal.Actions>
          <Button onClick={() => setModalOpen(false)}>
            Cancel Subscription
          </Button>
          <Button primary onClick={confirmSwitchPlan}>
            Confirm
          </Button>
        </Modal.Actions>
      </Modal>
      <Modal open={supportModalOpen} onClose={() => setSupportModalOpen(false)}>
        <Modal.Header>Contact Support</Modal.Header>
        <Modal.Content>
          <p>
            To switch between Basic and Premium subscriptions, please contact
            our support team at{" "}
            <a href="mailto:support@beechtree.ai">support@beechtree.ai</a>.
          </p>
        </Modal.Content>
        <Modal.Actions>
          <Button onClick={() => setSupportModalOpen(false)}>Close</Button>
        </Modal.Actions>
      </Modal>
      <Modal open={cancelModalOpen} onClose={() => setCancelModalOpen(false)}>
        <Modal.Header>Cancel Subscription</Modal.Header>
        <Modal.Content>
          <p>
            Are you sure you want to cancel your current subscription which ends
            on{" "}
            {new Date(
              currentSubscription?.currentPeriodEnd.seconds * 1000
            ).toLocaleDateString()}
            ?
          </p>
        </Modal.Content>
        <Modal.Actions>
          <Button onClick={() => setCancelModalOpen(false)}>No</Button>
          <Button color="red" onClick={handleCancelSubscription}>
            Yes, Cancel
          </Button>
        </Modal.Actions>
      </Modal>
    </Container>
  );
};

export default Subscriptions;
