// handleLogout.js

import { signOut } from "firebase/auth";
import { auth } from "../firebase";

// auth,
export const handleLogout = ({
  setImageUrls,
  setFirestoreUserData,
  navigate,
}) => {
  setFirestoreUserData(null);

  signOut(auth)
    .then(() => {
      setImageUrls([]);
      setFirestoreUserData(null);
      navigate("/");
    })
    .catch((err) => console.error(err.message));
};
