// BasicGroqCreateUI.jsx

import React, { useEffect, useState } from "react";
import { Button, Modal, Grid } from "semantic-ui-react";
import { createStoryMap } from "../../constants/siteTextMap";
import { useForm } from "react-hook-form";
import DesignChoicesSpecial from "../general/DesignChoicesSpecial";
import Lottie from "lottie-react";
import animatedRobot from "../../images/99973-little-power-robot.json";

const BasicGroqCreateUI = ({
  firestoreUserData,
  language,
  languageEmoji,
  submitStoryPrompt,
  isMobile,
  illustrationType,
  setIllustrationType,
  illustrationObject,
  setIllustrationObject,
  theme,
  paymentRequired,
  setShowNavbar,
  selectedThemeKey,
  setSelectedThemeKey,
  toggleTheme,
  themes,

  userPrompt,
  setUserPrompt,
}) => {
  const robotStyle = { height: 200 };

  const { register, handleSubmit, reset } = useForm({
    defaultValues: {
      userRequest: userPrompt, // Set initial form value
    },
  });

  const themeTitle =
    theme?.multilingualTitle && language !== "english"
      ? theme?.multilingualTitle[language.toLowerCase()]
      : theme?.title;

  useEffect(() => {
    setShowNavbar(true);
    reset({ userRequest: userPrompt }); // Reset form with new default value
  }, [userPrompt, reset]);

  const [themeModalOpen, setThemeModalOpen] = useState(false);

  const handleThemeSelect = (themeKey) => {
    setSelectedThemeKey(themeKey);
    setThemeModalOpen(false);
  };

  const getThemeEmoji = (themeKey) => {
    const emojiMap = {
      christmasTheme: "🎄",
      valentinesTheme: "💝",
      halloweenTheme: "🎃",
      easterTheme: "🐰",
      stPatricksTheme: "☘️",
      newYearsTheme: "🎉",
      summerTheme: "☀️",
      autumnTheme: "🍂",
      winterTheme: "❄️",
      springTheme: "🌸",
      gratitudeTheme: "🙏",
    };
    return emojiMap[themeKey] || "🎨";
  };

  const renderThemeModal = () => (
    <Modal
      open={themeModalOpen}
      onClose={() => setThemeModalOpen(false)}
      size="large"
      style={{
        position: "fixed",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        width: "80%",
        maxWidth: "1200px",
        height: "auto",
        maxHeight: "80vh",
      }}
    >
      <Modal.Header>Select a Theme</Modal.Header>
      <Modal.Content style={{ padding: "20px" }}>
        <Grid stackable columns={4} style={{ margin: "0" }}>
          <Grid.Row>
            {Object.entries(themes).map(
              ([key, theme]) =>
                key !== "defaultTheme" && (
                  <Grid.Column key={key} style={{ marginBottom: "20px" }}>
                    <Button
                      fluid
                      basic={selectedThemeKey !== key}
                      color={selectedThemeKey === key ? "black" : null}
                      onClick={() => handleThemeSelect(key)}
                      style={{
                        height: "120px",
                        width: "100%",
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        alignItems: "center",
                        border: "1px solid rgba(0,0,0,.2)",
                        background:
                          selectedThemeKey === key ? "black" : "white",
                        padding: "10px",
                        color: selectedThemeKey === key ? "white" : "black",
                      }}
                    >
                      <div style={{ fontSize: "2.5em", marginBottom: "10px" }}>
                        {getThemeEmoji(key)}
                      </div>
                      <div
                        style={{
                          fontSize: "1em",
                          textAlign: "center",
                          fontWeight:
                            selectedThemeKey === key ? "bold" : "normal",
                        }}
                      >
                        {theme.title}
                      </div>
                    </Button>
                  </Grid.Column>
                )
            )}
          </Grid.Row>
          <Grid.Row>
            <Grid.Column width={16}>
              <Button
                fluid
                basic
                onClick={() => {
                  handleThemeSelect(null);
                  setThemeModalOpen(false);
                }}
                style={{
                  height: "50px",
                  border: "1px solid rgba(0,0,0,.2)",
                  background: "white",
                  marginTop: "10px",
                }}
              >
                🔄 Clear Theme
              </Button>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Modal.Content>
    </Modal>
  );

  const handleThemeButtonClick = () => {
    console.log("Theme button clicked");
    setThemeModalOpen(true);
  };

  const [createButtonState, setCreateButtonState] = useState({
    isError: false,
    text: createStoryMap[language]?.create || createStoryMap["English"].create,
  });

  return (
    <>
      <style>
        {`
          @media (min-width: 601px) {
            textarea {
              width: 45ch;
            }
            h2 {
              font-size: 3.5em;
            }
          }

          @media (max-width: 600px) {
            textarea {
              width: 90%;
              box-sizing: border-box;
            }

            textarea::placeholder {
              font-size: .8em;
            }

            h2 {
              font-size: 1.8em;
            }

            button {
              font-size: 1em;
            }
          }
        `}
      </style>
      <div>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            marginBottom: "20px",
          }}
        ></div>

        {theme?.title ? (
          <h2 className="center" style={theme?.style}>
            {themeTitle}
          </h2>
        ) : (
          <h2 className="center">
            {createStoryMap[language]?.writingPromptDirections ||
              createStoryMap["English"].writingPromptDirections}
          </h2>
        )}

        <h6 style={{ fontSize: ".9em" }} className="center">
          Groq
        </h6>
        <Lottie animationData={animatedRobot} loop={true} style={robotStyle} />

        <div
          style={{
            display: "flex",
            justifyContent: "flex-end",
            marginBottom: "20px",
            marginRight: "20px",
          }}
        >
          <Button
            circular
            onClick={handleThemeButtonClick}
            color={selectedThemeKey ? theme?.style?.color : "grey"}
            style={{
              width: "60px",
              height: "60px",
              padding: "0",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              cursor: "pointer",
            }}
          >
            <span style={{ fontSize: "2em" }}>
              {selectedThemeKey ? getThemeEmoji(selectedThemeKey) : "🪄"}
            </span>
          </Button>
        </div>

        {renderThemeModal()}

        <div className="center">
          <form onSubmit={handleSubmit(submitStoryPrompt)}>
            <textarea
              type="text"
              placeholder={
                createStoryMap[language]?.placeholder ||
                createStoryMap["English"].placeholder
              }
              rows="6"
              {...register("userRequest", { required: true, maxLength: 1000 })}
              style={{
                fontSize: "1.5em",
                fontFamily: "Courier, monospace",
                boxSizing: "border-box",
              }}
            />
            <br />
            {theme?.title ? (
              <h2 style={{ ...theme?.style, ...{ fontSize: "2.5em" } }}>
                {createStoryMap[language]?.illustrationPrompt ||
                  createStoryMap["English"].illustrationPrompt}
              </h2>
            ) : (
              <h2 style={{ fontSize: "2.5em" }}>
                {createStoryMap[language]?.illustrationPrompt ||
                  createStoryMap["English"].illustrationPrompt}
              </h2>
            )}

            {/*       Image button demos */}
            <DesignChoicesSpecial
              language={language}
              languageEmoji={languageEmoji}
              illustrationType={illustrationType}
              setIllustrationType={setIllustrationType}
              illustrationObject={illustrationObject}
              setIllustrationObject={setIllustrationObject}
              color={theme?.style?.color}
              theme={theme}
            />
            <br />
            {firestoreUserData?.credit > 0 ? (
              <Button
                onClick={(e) => {
                  e.preventDefault();
                  const formValue = e.target.form?.userRequest?.value;
                  if (!formValue || formValue.trim() === "") {
                    setCreateButtonState({
                      isError: true,
                      text: "Please enter a prompt",
                    });
                    setTimeout(() => {
                      setCreateButtonState({
                        isError: false,
                        text:
                          createStoryMap[language]?.create ||
                          createStoryMap["English"].create,
                      });
                    }, 3000);
                    return;
                  }
                  handleSubmit(submitStoryPrompt)(e);
                }}
                size="massive"
                inverted={!createButtonState.isError}
                type="submit"
                color={
                  createButtonState.isError
                    ? "yellow"
                    : theme?.style?.color || "green"
                }
                style={{
                  fontSize: "1.5em",
                  width: isMobile ? "75%" : "200px",
                  transition: "all 0.3s ease",
                }}
              >
                {createButtonState.text}
              </Button>
            ) : (
              <Button
                onClick={paymentRequired}
                size="massive"
                type="submit"
                color="orange"
                style={{
                  fontSize: "1.5em",
                  width: isMobile ? "75%" : "200px",
                }}
              >
                Add Credits!
              </Button>
            )}
          </form>
        </div>
      </div>
    </>
  );
};

export default BasicGroqCreateUI;
