import React, { useState, useEffect } from "react";
import { Modal, Button, Form } from "semantic-ui-react";
import { story2Map } from "../../constants/siteTextMap";

const VocabularyItemEditModal = ({
  open,
  vocabItem,
  onSave,
  onClose,
  onDelete,
  currentLanguage,
  showSentence,
}) => {
  const [editedWord, setEditedWord] = useState("");
  const [editedDefinition, setEditedDefinition] = useState("");
  const [editedSentence, setEditedSentence] = useState("");

  useEffect(() => {
    if (vocabItem) {
      setEditedWord(vocabItem.word);
      setEditedDefinition(vocabItem.definition);
      setEditedSentence(vocabItem.sentence || "");
    }
  }, [vocabItem]);

  const handleSaveClick = () => {
    onSave({
      ...vocabItem,
      word: editedWord,
      definition: editedDefinition,
      sentence: editedSentence,
    });
    onClose();
  };

  const handleDeleteClick = () => {
    onDelete(vocabItem); // This calls the onDelete prop function, passing the vocabItem to delete
    onClose(); // Close the modal after deleting
  };

  return (
    <Modal open={open} onClose={onClose}>
      <Modal.Header>
        {story2Map[currentLanguage]?.editVocabulary ||
          story2Map["English"].editVocabulary}
      </Modal.Header>
      <Modal.Content>
        <Form>
          <Form.Field>
            <label>
              {story2Map[currentLanguage]?.word || story2Map["English"].word}
            </label>
            <input
              type="text"
              value={editedWord}
              onChange={(e) => setEditedWord(e.target.value)}
            />
          </Form.Field>
          <Form.Field>
            <label>
              {story2Map[currentLanguage]?.definition ||
                story2Map["English"].definition}
            </label>
            <input
              type="text"
              value={editedDefinition}
              onChange={(e) => setEditedDefinition(e.target.value)}
            />
          </Form.Field>

          <Form.Field>
            <label>
              {story2Map[currentLanguage]?.sentence ||
                story2Map["English"].sentence}
            </label>
            <input
              type="text"
              value={editedSentence}
              onChange={(e) => setEditedSentence(e.target.value)}
            />
          </Form.Field>
        </Form>
      </Modal.Content>
      <Modal.Actions>
        <Button onClick={onClose} color="orange">
          {" "}
          {story2Map[currentLanguage]?.cancel || story2Map["English"].cancel}
        </Button>
        <Button onClick={handleSaveClick} positive>
          {" "}
          {story2Map[currentLanguage]?.save || story2Map["English"].save}
        </Button>
        <Button onClick={handleDeleteClick} color="red">
          {story2Map[currentLanguage]?.delete || story2Map["English"].delete}
        </Button>
      </Modal.Actions>
    </Modal>
  );
};

export default VocabularyItemEditModal;
