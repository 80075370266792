import React from "react";

const StoryImage = ({
  retryImageGeneration,
  handleGenerateImage,
  getImageSource,
  data,
}) => {
  return (
    <div className="center">
      {retryImageGeneration ? (
        <div
          style={{
            width: "200px",
            height: "200px",
            backgroundColor: "#7fddfa",
            border: "5px solid black",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            cursor: "pointer",
            borderRadius: "10px",
          }}
          onClick={handleGenerateImage}
        >
          <span style={{ color: "black", fontSize: "1em", fontWeight: "bold" }}>
            Click to Generate Image
          </span>
        </div>
      ) : (
        <img
          id="storyImage"
          src={getImageSource(data)}
          height="500px"
          width="500px"
          alt=""
        />
      )}
    </div>
  );
};

export default StoryImage;
