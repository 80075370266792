import React from "react";

const StoryHeader = ({ title }) => {
  return (
    <div className="center">
      <h2 style={{ color: "black", fontSize: "3em" }}>{title}</h2>
    </div>
  );
};

export default StoryHeader;
