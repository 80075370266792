// StoryTextDisplayWrapperAnimated.jsx

import React, { useEffect } from "react";
import { motion, useAnimation } from "framer-motion";
import { Container } from "semantic-ui-react";

const sentenceAnimation = {
  hidden: { opacity: 1 },
  visible: {
    opacity: 1,
    transition: {
      delay: 0.5,
      staggerChildren: 0.06,
    },
  },
};

const wordAnimation = {
  hidden: { opacity: 0, y: "50%" },
  visible: { opacity: 1, y: 0 },
};

const StoryTextDisplayWrapperAnimated = ({
  storyText,
  wordBank,
  onAnimationComplete,
}) => {
  const controls = useAnimation();
  const storyTextArray = storyText?.split(/(\s+|\n+)/);
  const wordBankWord = {
    color: "green",
    cursor: "pointer",
    fontSize: "1.5em",
    fontWeight: 900,
  };
  const wordStyle = { color: "black", fontSize: "1.5em", cursor: "pointer" };

  // Validates wordBank format
  const isValidWordBank =
    Array.isArray(wordBank) &&
    wordBank.every((wb) => wb.hasOwnProperty("word"));

  // Update checkDictionary to search in wordBank objects
  function checkDictionary(word) {
    if (!isValidWordBank) return false; // Early return if wordBank is not valid

    let cleanWord = word
      .replace(/[^\w\s]|_/g, "")
      .replace(/\s+/g, " ")
      .toLowerCase();
    // Search for cleanWord in wordBank objects
    const wordExists = wordBank.some(
      (wb) => wb.word.toLowerCase() === cleanWord
    );
    if (wordExists) {
      return true;
    }
    // Check for singular form if word ends in 's'
    if (cleanWord.endsWith("s")) {
      let singularWord = cleanWord.slice(0, -1);
      return wordBank.some((wb) => wb.word.toLowerCase() === singularWord);
    }
    return false;
  }

  useEffect(() => {
    controls.start("visible").then(() => {
      if (onAnimationComplete) {
        onAnimationComplete();
      }
    });
  }, [controls, onAnimationComplete]);

  return (
    <motion.div
      className="story-text-wrapper"
      variants={sentenceAnimation}
      initial="hidden"
      animate={controls}
    >
      {storyTextArray.map((text, index) => {
        if (text === "\n") {
          return <br key={index} />;
        } else if (text.match(/^\s+$/)) {
          // Matches whitespace only
          // Apply a style that preserves whitespace but allows wrapping
          return (
            <span key={index} style={{ whiteSpace: "pre-wrap" }}>
              {text}
            </span>
          );
        } else {
          // For actual text content
          // Apply word bank styles or default text styles as before
          return (
            <motion.span
              key={index}
              style={
                isValidWordBank && checkDictionary(text)
                  ? wordBankWord
                  : wordStyle
              }
              variants={wordAnimation}
            >
              {text}
            </motion.span>
          );
        }
      })}
    </motion.div>
  );
};

export default StoryTextDisplayWrapperAnimated;
