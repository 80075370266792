// TransactionCancelled.jsx

import React from "react";
import { Container, Header, Segment } from "semantic-ui-react";

function TransactionCancelled() {
  return (
    <Container>
      <Header as="h1">Transaction Cancelled</Header>
      <Segment>
        <p>You have cancelled the transaction.</p>
        <p>
          If you have any questions or want to try again, please return to the
          checkout page.
        </p>
      </Segment>
    </Container>
  );
}

export default TransactionCancelled;
