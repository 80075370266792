import React from "react";
import { Button } from "semantic-ui-react";
import {
  createStoryMap,
  languageSelectionMap,
} from "../../constants/siteTextMap";
import { useForm } from "react-hook-form";
import ThemeToggleCheckbox from "./ThemeToggleCheckbox";

const AdvancedGroqCreate = ({ language, onSubmit }) => {
  const isMobile = window.innerWidth <= 600;
  const { register, handleSubmit, reset, errors } = useForm({});

  return (
    <>
      <style>
        {`
          @media (min-width: 601px) {
            /* Styles for larger screens */
          }

          @media (max-width: 600px) {
            /* Styles for mobile screens */
          }
        `}
      </style>
      <h2 style={{ color: "grey" }}>
        {createStoryMap[language]?.writingPromptDirections ||
          createStoryMap["English"].writingPromptDirections}
        {language !== "English" ? `${language} ` : null}
      </h2>
      <form onSubmit={handleSubmit(onSubmit)}>
        <textarea
          type="text"
          placeholder={
            createStoryMap[language]?.placeholder ||
            createStoryMap["English"].placeholder
          }
          rows="6"
          {...register("userRequest", { required: true, maxLength: 1000 })}
          style={
            {
              /* styles */
            }
          }
        />
        <br />
        {/* Conditional rendering for Button based on credits */}
        <br />
      </form>
      <br />
      <br />
    </>
  );
};

export default AdvancedGroqCreate;

{
  /* {firestoreUserData?.credit > 0
        ? <Button
          onClick={handleSubmit(submitStoryPrompt)}
          size="massive"
          inverted
          type="submit"
          color="green"
          style={{
            fontSize: '1.5em',
            width: isMobile ? '75%' : '200px'
          }}
        >
          {createStoryMap[language]?.create || createStoryMap['English'].create}
        </Button>
        : <Button
          onClick={() => navigate('/payment')}
          size="massive"
          type="submit"
          color="orange"
          style={{
            fontSize: '1.5em',
            width: isMobile ? '75%' : '200px'
          }}
        >
          Add Credits
        </Button>} */
}
