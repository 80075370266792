import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { getAuth, signInWithPopup, GoogleAuthProvider } from "firebase/auth";
import {
  setDoc,
  doc,
  getDoc,
  updateDoc,
  serverTimestamp,
} from "firebase/firestore";
import { ToastContainer, toast } from "react-toastify";
import { BEGINNING_CREDITS } from "../../constants/newUserConsts";
import checkDeletedUser from "../api/checkDeletedUser";
import { HELP_EMAIL } from "../../constants/adminConsts.js";
import { handleLogoutPromise } from "../../helpers/handleLogoutPromise";

const provider = new GoogleAuthProvider();

export default function LoginGoogle(props) {
  const auth = getAuth();
  const navigate = useNavigate();
  const { db, setShowNavbar, setFirestoreUserData, setImageUrls } = props;

  const showErrorDisplay = (message) =>
    toast.error(message, {
      position: "top-right",
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      draggable: true,
      theme: "light",
      onClose: () => navigate("/home"),
    });

  const rejectLogin = (message) => {
    console.log(`Reject login called with message: ${message}`);

    toast.error(message, {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      draggable: true,
      theme: "light",
      onClose: () => navigate("/"),
    });

    handleLogoutPromise({ setFirestoreUserData });
  };

  useEffect(() => {
    setShowNavbar(false);
  }, []);

  useEffect(() => {
    const loginWithGoogle = async () => {
      try {
        const result = await signInWithPopup(auth, provider);

        const googleUser = result.user;
        const email = googleUser.email;
        const userId = googleUser.uid;
        const docRef = doc(db, "users", userId);
        const docSnapshot = await getDoc(docRef);
        const currentFirebaseUser = docSnapshot.data();
        let incrementedLogins = 0;

        const emailAddressIsBanned = await checkDeletedUser(email);

        if (emailAddressIsBanned) {
          rejectLogin(
            `Sorry, but that email can not currently be used. If this is an error or you have any questions, email ${HELP_EMAIL}`
          );
          return;
        }

        if (!currentFirebaseUser) {
          const newUser = {
            accountType: "free",
            adminMessage: null,
            avatarSeed: userId,
            credit: BEGINNING_CREDITS,
            defaultLanguage: "English",
            email: googleUser.email,
            googleAuth: true,
            emailPasswordAuth: false,
            numberOfLogins: 1,
            newUser: true,
            newUserTermsAgreement: false,
            photoURL: googleUser.photoURL,
            suspendedAccount: false,
            suspensionMessage: null,
            userId,
            userDisplayName: googleUser.displayName,
            dateCreated: serverTimestamp(),
            dateLastLoggedIn: serverTimestamp(),
          };

          await setDoc(doc(db, "users", userId), newUser);
          navigate("/new-user-agreement");
        } else {
          incrementedLogins = currentFirebaseUser.numberOfLogins + 1;

          const dataToUpdate = {
            numberOfLogins: incrementedLogins,
            dateLastLoggedIn: serverTimestamp(),
          };

          await updateDoc(docRef, dataToUpdate);
          navigate("/home");
        }
      } catch (error) {
        showErrorDisplay(error.message);
      }
    };

    loginWithGoogle();
  }, []);

  return <ToastContainer />;
}
