// groq_1_createStory.js

import axios from "axios";
const baseUrl = process.env.REACT_APP_API_URL;

const buildGroqStory = ({
  userPrompt,
  additionalData,
  groqModel,
  cancelToken,
  intervalIdRef1,
}) => {
  console.log("---GROQ----");
  const storyGroqRequest = {
    model: groqModel,
    prompt: userPrompt,
    theme: additionalData?.theme,
    userId: additionalData?.userId,
    language: additionalData?.language,
  };

  return new Promise((resolve, reject) => {
    if (!cancelToken) {
      reject(new Error("Cancel token is not initialized"));
      return;
    }

    axios
      .post(baseUrl + "/groq-story-text/", storyGroqRequest, { cancelToken })
      .then((response) => {
        console.log("🔥🔥🔥🔥🔥 GROQ response.data ===>>", response.data);
        resolve(response.data);
      })
      .catch((err) => {
        console.log("Error in submitting the story prompt:", err.message);
        reject(err);
      });
  });
};

export default buildGroqStory;
