// NewStoryAddPicture.jsx

import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import { useNavigate, useLocation } from "react-router-dom";
import { Container, Button } from "semantic-ui-react";

import buildVisualPromptGetImage from "../../api/buildVisualPromptGetImage.js";
import updateStoryImage from "../../api/updateStoryImage.js";
import saveUnfinishedStory from "../../api/saveUnfinishedStory.js";
import Lottie from "lottie-react";
import savingDots from "../../../images/savingDots.json";
import AiOrbLoader from "../../../images/136808-ai-orb.json";
import sadRobot from "../../../images/sad_robot.json";

import RevealImage from "../../general/RevealImage.jsx";
import { getStorage, ref, getDownloadURL } from "firebase/storage";

function NewStoryAddPicture(props) {
  const { currentUser, setShowNavbar, firestoreUserData, db } = props;

  let navigate = useNavigate();
  let location = useLocation();
  const storage = getStorage();

  const [fullStoryObject] = useState(location.state?.fullStoryObject);

  const {
    title,
    blurb,
    prompt,
    text,
    ageLevel,
    summary,
    language,
    conflict,
    resolution,
    theme,
    languageEmoji,
    dateCreated,
    dateModified,
    quizGenerated,
    quizQuestions = [],
    illustrationObject,
    illustrationObject: { illustrationType },
    retryImageGeneration,
    storyId,
    vocabulary,
    setting,
    characters,
  } = fullStoryObject || {};

  const createUrl = process.env.REACT_APP_CREATE_STORY_ACTIVE;

  console.log("fullStoryObject", fullStoryObject);

  const cancelTokenSourceRef = useRef(null); // Ref for the cancel token
  const intervalIdRef = useRef(null); // Ref for the interval ID

  const [selectedItem, setSelectedItem] = useState(null);

  const [imagesObject, setImagesObject] = useState(null);
  const [currentImageUrl, setCurrentImageUrl] = useState(null);
  const [imageApiResponseTime, setImageApiResponseTime] = useState(null);
  const [entireImageResponse, setEntireImageResponse] = useState(null);
  const [generatedImagePrompt, setGeneratedImagePrompt] = useState(null);

  const [showSavingGraphic, setShowSavingGraphic] = useState(false);
  const [serverError, setServerError] = useState(false);
  const [showQuiz, setShowQuiz] = useState(false);
  const [savingStory, setSavingStory] = useState(false);
  const [successfulSave, setSuccessfulSave] = useState(false);
  const [updatedFields, setUpdatedFields] = useState(false);

  const [storyObject, setStoryObject] = useState({
    ...fullStoryObject,
  });

  useEffect(() => {
    if (successfulSave) {
      navigate("/story2/" + storyId);
    }
  }, [successfulSave, storyId, navigate]);

  useEffect(() => {
    cancelTokenSourceRef.current = axios.CancelToken.source();
    callBuildVisualPromptGetImage();
    return () => {
      cancelTokenSourceRef.current?.cancel(
        "Component unmounted, request cancelled"
      );
      clearInterval(intervalIdRef.current); // Clear interval on component unmount
    };
  }, []);

  const charactersDescriptionText = characters
    ? JSON.stringify(characters)
    : "";

  // TO DO: extract getDownloadURLWithRetry
  function getDownloadURLWithRetry(ref, path, retries = 5, delay = 1000) {
    return new Promise((resolve, reject) => {
      const attemptDownload = (retriesLeft) => {
        getDownloadURL(ref, path)
          .then(resolve)
          .catch((error) => {
            if (retriesLeft === 0) {
              reject(error);
            } else {
              setTimeout(() => attemptDownload(retriesLeft - 1), delay);
            }
          });
      };
      attemptDownload(retries);
    });
  }

  // make sure origin illustartion genre sent
  // check buildVisuual
  // mase sure genertaedPromtt is saved

  const callBuildVisualPromptGetImage = async () => {
    try {
      const response = await buildVisualPromptGetImage(
        {
          userId: currentUser?.uid,

          theme,
          title,
          summary,
          setting,
          charactersDescription: charactersDescriptionText,

          illustrationType,
          generatedStoryId: storyId,
        },
        cancelTokenSourceRef.current.token,
        intervalIdRef
      );

      const {
        entireImagePromptResponse,
        images,
        imageApiResponseTime,
        imagePrompt,
      } = response;

      setImagesObject(images);
      setEntireImageResponse(entireImagePromptResponse);
      setImageApiResponseTime(imageApiResponseTime);
      setGeneratedImagePrompt(imagePrompt); // not working?

      const downloadMediumURL = await getDownloadURLWithRetry(
        ref(storage, images?.imageThumbMediumURL)
      );
      setCurrentImageUrl(downloadMediumURL);

      // Update storyObject here with the new imagesObject
      setStoryObject((prev) => ({
        ...prev,
        ...fullStoryObject,
        imagesObject: images,
        imageDownloadURL: images?.imageDownloadURL,
        imageThumbMediumURL: images?.imageThumbMediumURL,
        imageThumbDownloadURL: images?.imageThumbDownloadURL,
      }));

      setUpdatedFields({
        imagesObject: images,
        imagePromptGenerated: imagePrompt,
        imageDownloadURL: images?.imageDownloadURL,
        imageThumbMediumURL: images?.imageThumbMediumURL,
        imageThumbDownloadURL: images?.imageThumbDownloadURL,
      });
    } catch (error) {
      console.error("Error calling buildVisualPromptGetImage:", error);
      setServerError(true); // Set server error to true to handle the UI
    }
  };

  const toggleItem = (itemIndex) => {
    setSelectedItem(selectedItem === itemIndex ? null : itemIndex);
  };

  const toggleQuizVisibility = () => {
    setShowQuiz(!showQuiz);
  };

  const handleCancel = () => {
    setImagesObject(null);
    setCurrentImageUrl(null);
    setImageApiResponseTime(null);
    setEntireImageResponse(null);
    setGeneratedImagePrompt(null);
    setShowSavingGraphic(false);
    setServerError(false);
    setShowQuiz(false);
    setSavingStory(false);
    setSuccessfulSave(false);

    navigate(createUrl);
  };

  // using ...newStory + ...storyObject
  const handleSaveUnfinishedStory = () => {
    const updatedStoryObject = { ...storyObject, retryImageGeneration: true };

    setStoryObject(updatedStoryObject);

    saveUnfinishedStory({
      currentUser,
      db,
      setShowSavingGraphic,
      setShowNavbar,
      setServerError,
      updatedStoryObject,
      setSavingStory,
      setSuccessfulSave,
      language,
      languageEmoji,
    });
    navigate(createUrl);
  };

  // Cancel button JSX
  const CancelButton = () => (
    <div className="center">
      <Button
        color="black"
        onClick={() => {
          // Cancel the API request if ongoing
          if (cancelTokenSourceRef.current) {
            cancelTokenSourceRef.current.cancel(
              "API request cancelled by the user."
            );
          }
          handleCancel();
        }}
      >
        ❌ Cancel
      </Button>
    </div>
  );

  // Error message UI
  const ErrorMessageUI = () => (
    <div
      style={{
        border: "5px solid red",
        margin: "10px auto",
        width: "75%",
        padding: "10px",
      }}
    >
      <h1 style={{ textAlign: "center" }}>
        Oops, There has been an error on the server and we couldn't generate the
        image for your story.
      </h1>
      <Lottie
        animationData={sadRobot}
        loop={false}
        style={{ height: 200, margin: "auto" }}
      />
      <h1 style={{ textAlign: "center" }}>
        Would you like to save the story and add an image later?
      </h1>
      <div style={{ textAlign: "center", marginBottom: "10px" }}>
        <Button color="green" onClick={handleSaveUnfinishedStory}>
          Save Story and Retry Later
        </Button>
      </div>
      <div style={{ textAlign: "center" }}>
        <Button color="red" onClick={handleCancel}>
          Cancel
        </Button>
      </div>
    </div>
  );

  if (savingStory) {
    return (
      <Lottie
        animationData={savingDots}
        loop={true}
        style={{ height: 600, position: "relative" }}
      />
    );
  }

  if (!fullStoryObject) return <h1>Loading story...</h1>;

  return (
    <Container>
      <h2 className="center">{title}</h2>
      <h4 className="center">{blurb}</h4>

      {serverError ? (
        <ErrorMessageUI />
      ) : currentImageUrl ? (
        <>
          <div className="center">
            <RevealImage src={currentImageUrl} alt="ai_image" />
          </div>
          <br />
        </>
      ) : (
        <Lottie
          animationData={AiOrbLoader}
          loop={true}
          style={{ height: 200, position: "relative" }}
        />
      )}

      <p>{text}</p>
      <br />

      <Button onClick={() => toggleItem("summary")}>Show Summary</Button>
      {selectedItem === "summary" && <p>{summary}</p>}

      <Button onClick={() => toggleItem("conflict")}>Show Conflict</Button>
      {selectedItem === "conflict" && <p>{conflict}</p>}

      <Button onClick={() => toggleItem("resolution")}>Show Resolution</Button>
      {selectedItem === "resolution" && <p>{resolution}</p>}

      <Button onClick={() => toggleItem("theme")}>Show Theme</Button>
      {selectedItem === "theme" && <p>{theme}</p>}

      <Button onClick={() => toggleItem("vocabulary")}>Show Vocabulary</Button>
      {selectedItem === "vocabulary" && (
        <ul>
          {vocabulary.map((word, i) => (
            <li key={i}>
              <strong>{word.word}:</strong> {word.definition}
            </li>
          ))}
        </ul>
      )}

      <br />
      <Button onClick={toggleQuizVisibility}>
        {showQuiz ? "Hide Quiz" : "Show Quiz"}
      </Button>
      {showQuiz &&
        fullStoryObject.quizArray.map((quizItem, i) => (
          <div key={i}>
            <Button onClick={() => toggleItem(`question_${i}`)}>
              #{i + 1} {quizItem[`question_${i}`]}
            </Button>
            {selectedItem === `question_${i}` && (
              <p>{quizItem[`answer_${i}`]}</p>
            )}
          </div>
        ))}

      <br />

      {!currentImageUrl && !serverError && <CancelButton />}

      {currentImageUrl && !serverError && (
        <div className="center">
          <Button
            size="huge"
            color="green"
            onClick={() =>
              updateStoryImage({
                currentUser,
                db,
                storyId,

                setShowSavingGraphic,
                setShowNavbar,
                setServerError,
                setSuccessfulSave,
                setSavingStory,

                updatedFields,
              })
            }
          >
            Save New Picture!
          </Button>
        </div>
      )}
    </Container>
  );
}

export default NewStoryAddPicture;
