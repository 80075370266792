// DisplayStories.jsx

import React, { useEffect, useState } from "react";
import { collection, onSnapshot, deleteDoc, doc } from "firebase/firestore";
import DisplayCard from "./DisplayCard";
import updateStory from "../api/updateStory";
import {
  Grid,
  Button,
  List,
  Icon,
  Table,
  Checkbox,
  Modal,
  Header,
} from "semantic-ui-react";
import { formatFirestoreTimestamp } from "../utils/formatFirestoreTimestamp";
import sortAtoZ from "../../images/ui/alpha-a-z.png";
import sortZtoA from "../../images/ui/alpha-z-a.png";

// Add this function at the top to check for mobile view
const isMobileView = () => window.innerWidth <= 768;

export default function DisplayStories(props) {
  const {
    db,
    userId,
    currentUser,
    storyHandler,
    isListView,
    savedStories,
    setSavedStories,
    selectedStories,
    setSelectedStories,
    onCheckboxChange,
    showCheckboxes,
    setShowDeleteButton,
    showDeleteModal,
    setShowDeleteModal,
  } = props;

  const [sortField, setSortField] = useState("title");
  const [sortDirection, setSortDirection] = useState("ascending");

  const handleSort = (clickedColumn) => {
    if (sortField !== clickedColumn) {
      setSortField(clickedColumn);
      setSortDirection("ascending");

      const sortedStories = [...savedStories].sort((a, b) => {
        if (clickedColumn === "dateCreated") {
          const getTimestamp = (item) => {
            if (!item.dateCreated) return 0;
            if (item.dateCreated.seconds) return item.dateCreated.seconds;
            if (typeof item.dateCreated === "string")
              return new Date(item.dateCreated).getTime() / 1000;
            return 0;
          };

          const timestampA = getTimestamp(a);
          const timestampB = getTimestamp(b);
          return timestampA - timestampB;
        }

        if (clickedColumn === "favorited") {
          return (b.favorited ? 1 : 0) - (a.favorited ? 1 : 0);
        }

        return (a[clickedColumn] || "").localeCompare(b[clickedColumn] || "");
      });

      setSavedStories(sortedStories);
    } else {
      setSortDirection(
        sortDirection === "ascending" ? "descending" : "ascending"
      );
      setSavedStories([...savedStories].reverse());
    }
  };

  const keepFirstXWords = (text, numberOfWords) => {
    return text.split(" ").slice(0, numberOfWords);
  };

  const toggleFavorite = async (item) => {
    await updateStory({
      userId,
      storyId: item.storyId,
      db,
      updatedFields: { favorited: !item.favorited },
    });
  };

  const storyDisplay = (item, textDisplay) => {
    const shouldShowGenerateImage =
      !item.imageDownloadURL && !item.imageMediumDownloadURL;

    return (
      <>
        <DisplayCard
          handleClick={() => storyHandler(item.storyId)}
          title={item?.title}
          dateCreated={item?.dateCreated}
          dateModified={item?.dateModified}
          imageURL={{
            imageThumbDownloadURL: item.imageThumbDownloadURL,
            imageMediumDownloadURL: item.imageMediumDownloadURL,
            imageDownloadURL: item.imageDownloadURL,
          }}
          text={textDisplay}
          languageEmoji={item?.languageEmoji}
          center={!isListView}
          favorited={item.favorited}
          onHeartClick={() => toggleFavorite(item)}
          showGenerateImage={shouldShowGenerateImage}
        />
      </>
    );
  };

  const archiveItem = ({ userId, item, db }) => {
    //console.log("ARCHIVE");
    updateStory({
      userId,
      storyId: item.storyId,
      db,
      updatedFields: { archived: true },
    });
  };

  const getImageSource = ({
    imageThumbDownloadURL,
    imageMediumDownloadURL,
    imageDownloadURL,
  }) => {
    if (imageThumbDownloadURL) return imageThumbDownloadURL;
    if (imageMediumDownloadURL) return imageMediumDownloadURL;
    return imageDownloadURL;
  };

  const handleCheckAll = (checked) => {
    if (checked) {
      // Select all stories
      const allStoryIds = savedStories.map((story) => story.id);
      onCheckboxChange(allStoryIds);
    } else {
      // Deselect all stories
      onCheckboxChange([]);
    }
  };

  const handleMassDelete = () => {
    setShowDeleteModal(true);
  };

  const handleConfirmDelete = async () => {
    try {
      const promises = selectedStories.map((storyId) =>
        deleteDoc(doc(db, "users", currentUser.uid, "stories", storyId))
      );
      await Promise.all(promises);
      setSelectedStories([]);
      setShowDeleteButton(false);
      setShowDeleteModal(false);
    } catch (error) {
      console.error("Error deleting stories:", error);
    }
  };

  const DeleteConfirmModal = () => {
    const selectedStoryTitles = savedStories
      .filter((story) => selectedStories.includes(story.id))
      .map((story) => story.title);

    return (
      <Modal
        open={showDeleteModal}
        onClose={() => setShowDeleteModal(false)}
        size="small"
      >
        <Header icon>
          <Icon name="trash" />
          Delete Stories
        </Header>
        <Modal.Content>
          <p>Are you sure you want to delete:</p>
          <ul style={{ maxHeight: "200px", overflowY: "auto" }}>
            {selectedStoryTitles.map((title, index) => (
              <li key={index} style={{ marginBottom: "8px" }}>
                {title}
              </li>
            ))}
          </ul>
        </Modal.Content>
        <Modal.Actions>
          <Button color="red" onClick={() => setShowDeleteModal(false)}>
            <Icon name="remove" /> No
          </Button>
          <Button color="green" onClick={handleConfirmDelete}>
            <Icon name="checkmark" /> Yes
          </Button>
        </Modal.Actions>
      </Modal>
    );
  };

  const savedStoryCollectionDisplay =
    savedStories &&
    (isListView ? (
      <div style={{ overflowX: "auto", WebkitOverflowScrolling: "touch" }}>
        <Table
          celled
          sortable
          unstackable
          style={{
            minWidth: isMobileView() ? "300px" : "650px", // Reduced minimum width for mobile
          }}
        >
          <Table.Header>
            <Table.Row>
              {showCheckboxes && (
                <Table.HeaderCell width={1}>
                  <Checkbox
                    checked={selectedStories.length === savedStories.length}
                    indeterminate={
                      selectedStories.length > 0 &&
                      selectedStories.length < savedStories.length
                    }
                    onChange={(e, data) => handleCheckAll(data.checked)}
                  />
                </Table.HeaderCell>
              )}
              <Table.HeaderCell
                sorted={sortField === "title" ? sortDirection : null}
                onClick={() => handleSort("title")}
              >
                Title
              </Table.HeaderCell>
              <Table.HeaderCell width={2}>Thumbnail</Table.HeaderCell>
              {!isMobileView() && (
                <>
                  <Table.HeaderCell
                    sorted={
                      sortField === "languageEmoji" ? sortDirection : null
                    }
                    onClick={() => handleSort("languageEmoji")}
                  >
                    Language
                  </Table.HeaderCell>
                  <Table.HeaderCell
                    sorted={sortField === "dateCreated" ? sortDirection : null}
                    onClick={() => handleSort("dateCreated")}
                  >
                    Date
                  </Table.HeaderCell>
                </>
              )}
              <Table.HeaderCell
                sorted={sortField === "favorited" ? sortDirection : null}
                onClick={() => handleSort("favorited")}
              >
                Favorite
              </Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {savedStories.map((story) => {
              //console.log("Story in list:", story);
              return (
                <Table.Row key={story.id}>
                  {showCheckboxes && (
                    <Table.Cell>
                      <Checkbox
                        checked={selectedStories.includes(story.id)}
                        onChange={() => onCheckboxChange(story.id)}
                      />
                    </Table.Cell>
                  )}
                  <Table.Cell
                    onClick={() => storyHandler(story.id)}
                    style={{ cursor: "pointer" }}
                  >
                    {story.title}
                  </Table.Cell>
                  <Table.Cell
                    onClick={() => storyHandler(story.id)}
                    style={{ cursor: "pointer" }}
                  >
                    {getImageSource(story) && (
                      <img
                        src={getImageSource(story)}
                        alt="thumbnail"
                        style={{
                          width: "100%",
                          height: "auto",
                          objectFit: "contain",
                          borderRadius: "4px",
                          maxHeight: "200px",
                          backgroundColor: "#f9f9f9",
                        }}
                      />
                    )}
                  </Table.Cell>
                  {!isMobileView() && (
                    <>
                      <Table.Cell
                        onClick={() => storyHandler(story.id)}
                        style={{ cursor: "pointer" }}
                      >
                        <span style={{ fontSize: "3em" }}>
                          {story.languageEmoji}
                        </span>
                      </Table.Cell>
                      <Table.Cell
                        onClick={() => storyHandler(story.id)}
                        style={{ cursor: "pointer" }}
                      >
                        {formatFirestoreTimestamp(story.dateCreated)}
                      </Table.Cell>
                    </>
                  )}
                  <Table.Cell>
                    <Icon
                      name={story.favorited ? "heart" : "heart outline"}
                      color={story.favorited ? "red" : "grey"}
                      onClick={(e) => {
                        e.stopPropagation();
                        toggleFavorite(story);
                      }}
                      style={{
                        cursor: "pointer",
                        fontSize: "2em",
                      }}
                    />
                  </Table.Cell>
                </Table.Row>
              );
            })}
          </Table.Body>
        </Table>
      </div>
    ) : (
      <Grid
        container
        columns={3}
        doubling
        stackable
        className="center-content"
        style={{
          padding: "0 10px",
          justifyContent: "center",
        }}
      >
        {savedStories.map((item) => {
          if (item?.archived) return null;
          const textDisplay = item?.blurb
            ? item.blurb
            : item.text && keepFirstXWords(item.text, 20).join(" ") + "...";

          return (
            <Grid.Column
              key={item.storyId}
              style={{
                maxWidth: window.innerWidth <= 768 ? "300px" : "33.33%",
                marginBottom: "20px",
              }}
            >
              {storyDisplay(item, textDisplay)}
            </Grid.Column>
          );
        })}
      </Grid>
    ));

  return (
    <>
      {savedStories && savedStoryCollectionDisplay}
      <DeleteConfirmModal />
    </>
  );
}
