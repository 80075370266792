// DisplayCard.jsx

import React, { useState } from "react";
import { Card, Image, Icon } from "semantic-ui-react";

const DisplayCard = ({
  title,
  text,
  textSize,
  imageURL,
  handleClick,
  languageEmoji,
  favorited,
  onHeartClick,
  dateCreated,
  dateModified,
  showGenerateImage,
}) => {
  const [mouseOver, setMouseOver] = useState(false);

  const options = { year: "numeric", month: "long", day: "numeric" };

  const dateCreatedDisplay =
    dateCreated &&
    new Date(dateCreated.seconds * 1000).toLocaleDateString(undefined, options);
  const dateModifiedDisplay =
    dateModified &&
    new Date(dateModified.seconds * 1000).toLocaleDateString(
      undefined,
      options
    );

  const now = new Date();
  const dateCreatedObj = dateCreated && new Date(dateCreated.seconds * 1000);
  const daysSinceCreated =
    dateCreatedObj && Math.ceil((now - dateCreatedObj) / (1000 * 60 * 60 * 24));

  const generateImageContent = (
    <div
      style={{
        width: "150px",
        height: "150px",
        backgroundColor: "#ba03fc",
        border: "3px solid black",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        cursor: "pointer",
        borderRadius: "5px", // Optional: for rounded corners
      }}
    >
      <span style={{ color: "black", fontSize: "1em", fontWeight: "bold" }}>
        No Image
      </span>
    </div>
  );

  const getImageSource = (imageData) => {
    if (!imageData) return null;
    if (imageData.imageMediumDownloadURL)
      return imageData.imageMediumDownloadURL;
    if (imageData.imageDownloadURL) return imageData.imageDownloadURL;
    return imageData.imageThumbDownloadURL;
  };

  return (
    <Card
      onClick={handleClick}
      style={{
        position: "relative",
        width: window.innerWidth <= 768 ? "300px" : "100%", // Smaller on mobile
        margin: window.innerWidth <= 768 ? "0 auto" : null, // Center on mobile
      }}
      onMouseEnter={() => setMouseOver(true)}
      onMouseLeave={() => setMouseOver(false)}
    >
      {showGenerateImage ? (
        <div className="center">{generateImageContent}</div>
      ) : (
        <Image
          src={getImageSource(imageURL)}
          wrapped
          ui={false}
          style={{
            height: window.innerWidth <= 768 ? "150px" : "200px", // Smaller height on mobile
            objectFit: "cover",
          }}
        />
      )}

      <div
        onClick={(e) => {
          e.stopPropagation();
          onHeartClick();
        }}
        style={{
          cursor: "pointer",
          position: "absolute",
          top: "5px",
          right: "5px",
          zIndex: "1",
          backgroundColor: favorited ? "black" : "rgba(240, 240, 240, 0.5)", // 50% transparency when not favorited

          borderRadius: "50%", // This makes the div circular
          width: "40px", // Width of the circle
          height: "40px", // Height of the circle
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }} // Positions the heart on the top right corner of the Card
      >
        <Icon
          name={favorited ? "heart" : "heart outline"}
          color={favorited ? "red" : "grey"} // Heart is red when favorited, grey otherwise
          style={{
            fontSize: "20px", // Adjust the size as needed
            marginRight: "0px", // Move the heart a bit to the right
          }}
        />
      </div>
      <Card.Content>
        <Card.Header>{title}</Card.Header>
        <Card.Meta>{dateCreatedDisplay}</Card.Meta>
        <Card.Description>{text}</Card.Description>
      </Card.Content>

      <Card.Content extra>
        <div style={{ display: "flex", alignItems: "center" }}>
          <span
            style={{
              fontSize: "2em",
              marginRight: "10px",
              visibility: mouseOver ? "visible" : "hidden",
            }}
          >
            {languageEmoji}
          </span>
          <span
            style={{
              fontSize: "1.2em",
              visibility: mouseOver ? "visible" : "hidden",
            }}
          >
            {"Days:" + daysSinceCreated}
          </span>
        </div>
      </Card.Content>
    </Card>
  );
};

export default DisplayCard;
