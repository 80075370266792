import React from "react";
import StoryTextWrapper from "./StoryTextWrapper.jsx";

const StoryText = ({ text, vocabulary }) => {
  return (
    <>{text && <StoryTextWrapper storyText={text} wordBank={vocabulary} />}</>
  );
};

export default StoryText;
