import React from "react";
import Lottie from "lottie-react";
import DisplayLinkQrEdit from "../../general/DisplayLinkQrQuizEdit.jsx";
import simpleLoader from "../../../images/simpleLoader.json";

const ExportStory = ({
  loadingStaticLink,
  generatedStaticLink,
  showQRCode,
  qrCodeSize,
  handleQRCodeClick,
  linkCopied,
  graphicExport,
  generateNewStaticLink,
  graphicQR,
  generateQRCode,
  showTestMaker,
  testImage,
  currentlyGeneratingQuestion,
  editStory,
  setLoadingStaticLink,
  title,
  text,
  quizArray,
  imageDownloadURL,
  imageMediumDownloadURL,
  currentUser,
  baseUrl,
  storyId,
  updateStory,
  db,
  setGeneratedStaticLink,
  setLinkCopied,
  story2Map,
  currentLanguage,
}) => {
  if (loadingStaticLink || generatedStaticLink) {
    return loadingStaticLink ? (
      <div className="center">
        <Lottie
          animationData={simpleLoader}
          loop={true}
          style={{ height: 100, position: "relative" }}
        />
      </div>
    ) : (
      <div
        style={{
          backgroundColor: "#dcedfc",
          padding: "15px",
          borderRadius: "10px",
          border: "1px solid black",
        }}
      >
        {DisplayLinkQrEdit({
          showQRCode,
          generatedStaticLink,
          qrCodeSize,
          handleQRCodeClick,
          linkCopied,
          graphicExport,
          generateNewStaticLink,
          graphicQR,
          generateQRCode,
          showTestMaker,
          testImage,
          currentlyGeneratingQuestion,
          editStory,
          setLoadingStaticLink,
          title,
          text,
          quizArray,
          imageDownloadURL,
          imageMediumDownloadURL,
          currentUser,
          baseUrl,
          storyId,
          updateStory,
          db,
          setGeneratedStaticLink,
          setLinkCopied,
          story2Map,
          currentLanguage,
        })}
      </div>
    );
  } else return null;
};

export default ExportStory;
