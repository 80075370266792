// adjustUserCredit.js

import updateUserPromise from "./updateUserPromise";

const adjustUserCredit = async (firestoreUserData) => {
  let currentCredit = firestoreUserData?.credit || 0;
  let textTokens = Array.isArray(firestoreUserData?.textTokens)
    ? [...firestoreUserData.textTokens]
    : [];
  const textTokensUsed = {
    /* Your logic to define used text tokens */
  };

  console.log("🔵 🔵 🔵 NEW: textTokensUsed", textTokensUsed);
  console.log("🟢 🟢 🟢 CURRENT: textTokens", textTokens);

  textTokens.push(textTokensUsed);
  const updateObject = {
    credit: currentCredit - parseInt(process.env.REACT_APP_IMAGE_CREDIT),
    textTokens, // Ensure to spread or concatenate the original array to avoid direct mutation
  };
  await updateUserPromise({
    userId: firestoreUserData.userId,
    updatedFields: updateObject,
  });
};

export default adjustUserCredit;

// Adjusted call within adjustUserCredit
