// TransactionFailure.jsx
import React from "react";
import { Container, Header, Segment, List } from "semantic-ui-react";
import { useLocation } from "react-router-dom";

function TransactionFailure({ ...commonProps }) {
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const reason = params.get("reason");

  return (
    <Container>
      <Header as="h1">Transaction Failed</Header>
      <Segment>
        {reason === "payment_failed" ? (
          <>
            <p>We're sorry, but your payment could not be processed.</p>
            <p>Possible reasons for failure:</p>
            <List bulleted>
              <List.Item>Insufficient funds</List.Item>
              <List.Item>Card declined</List.Item>
              <List.Item>Technical issue</List.Item>
            </List>
          </>
        ) : (
          <p>We're sorry, but your transaction could not be completed.</p>
        )}
        <p>
          Please try again or contact customer support if the problem persists.
        </p>
      </Segment>
    </Container>
  );
}

export default TransactionFailure;
