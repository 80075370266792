// QuizItemEditModal.jsx
import React, { useState, useEffect } from 'react';
import { Modal, Button, Form } from 'semantic-ui-react';
import { story2Map } from '../../constants/siteTextMap'

const QuizItemEditModal = ({ open, quizItem, onSave, onClose, onDelete, currentLanguage }) => {
  const [editedQuestion, setEditedQuestion] = useState('');
  const [editedAnswer, setEditedAnswer] = useState('');

  useEffect(() => {
    if (quizItem) {
      setEditedQuestion(quizItem.question);
      setEditedAnswer(quizItem.answer)
    }
  }, [quizItem]);

  const handleSaveClick = () => {
    onSave({ ...quizItem, question: editedQuestion, answer: editedAnswer });
    onClose();
  };



  const handleDeleteClick = () => {
    onDelete(quizItem); // This triggers the deletion logic in StoryResources
    onClose(); // Close the modal
  };




  return (
    <Modal open={open} onClose={onClose}>
      <Modal.Header>  {story2Map[currentLanguage]?.editQuiz || story2Map['English'].editQuiz}</Modal.Header>
      <Modal.Content>
        <Form>
          <Form.Field>
            <label> {story2Map[currentLanguage]?.question || story2Map['English'].question}</label>
            <input
              type="text"
              value={editedQuestion}
              onChange={(e) => setEditedQuestion(e.target.value)} />
          </Form.Field>
          <Form.Field>
            <label> {story2Map[currentLanguage]?.answer || story2Map['English'].answer}</label>
            <input
              type="text"
              value={editedAnswer}
              onChange={(e) => setEditedAnswer(e.target.value)} />
          </Form.Field>
        </Form>
      </Modal.Content>
      <Modal.Actions>
        <Button
          onClick={onClose} color="orange">{story2Map[currentLanguage]?.cancel || story2Map['English'].cancel}</Button>
        <Button onClick={handleSaveClick} positive>{story2Map[currentLanguage]?.save || story2Map['English'].save}</Button>
        <Button onClick={handleDeleteClick} color="red">{story2Map[currentLanguage]?.delete || story2Map['English'].delete}</Button> {/* Add this line */}
      </Modal.Actions>
      {/* <Modal.Actions>
        <Button onClick={onClose} negative> {story2Map[currentLanguage]?.cancel || story2Map['English'].cancel}</Button>
        <Button onClick={handleSaveClick} positive>
          {story2Map[currentLanguage]?.save || story2Map['English'].save}
        </Button>
      </Modal.Actions> */}
    </Modal>
  );
};

export default QuizItemEditModal;
