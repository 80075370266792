// StortDisplay2 - CURRENT FILE
//  is a component that displays a story in a read-only mode.
// It is used to display a story to the user, and provides options to share the story, view metadata, and view basic AI features.
// The component loads StoryResources, which displays quesiton and answer pairs for the story as well as vocabulary words.

import React, { useState, useEffect, useRef } from "react";
import { db, auth } from "../../../firebase.js";
import { useParams, useNavigate } from "react-router-dom";
import { Container, Button, Checkbox, Modal } from "semantic-ui-react";
import Lottie from "lottie-react";
import { ToastContainer } from "react-toastify";
// import OptionsBarBasic from "./OptionsBarBasic.jsx";
// import OptionsBarFree from "./OptionsBarFree.jsx";
// import OptionsBarPremium from "./OptionsBars.jsx";
// import OptionsBarCrypto from "./OptionsBarCrypto.jsx";

import {
  OptionsBarFree,
  OptionsBarBasic,
  OptionsBarPremium,
} from "./OptionsBars.jsx";
import "react-toastify/dist/ReactToastify.css";
import "../../../styles/StoryDisplay.css";

// Custom hooks and components
import useGetStory from "../../hooks/useGetStory.js";
import usePagePersist from "../../hooks/usePagePersist.js";
// import PDFGenerator from "../../pdf/PDFGenerator.js";
// import DisplayLinkQrEdit from "../../general/DisplayLinkQrQuizEdit.jsx";
// import StoryResources from "./StoryResources.jsx";
import SubscriptionStatusButton from "./SubscriptionStatusButton.jsx";

// API and helpers
import updateStory from "../../api/updateStory.js";
import generateNewStaticLink from "../../general/generateNewStaticLink.js";
import { story2Map } from "../../../constants/siteTextMap.js";

// Images and animations
import graphicQR from "../../../images/ui/qr-code.png";
import testImage from "../../../images/ui/test.png";
import graphicExport from "../../../images/ui/export.png";
import simpleLoader from "../../../images/simpleLoader.json";
import trashBin from "../../../images/111436-move-to-trash.json";

import FileMetaData from "./FileMetaData.jsx";
import ExportStory from "./ExportStory.jsx";
import StoryHeader from "./StoryHeader.jsx";
import StoryText from "./StoryText.jsx";
import StoryImage from "./StoryImage.jsx";
import StoryResources from "./StoryResources.jsx";

import PDFGenerator from "../../pdf/PDFGenerator";

const baseUrl = process.env.REACT_APP_API_URL;

// Helper functions
function splitWords(text) {
  if (!text) return [null, null];
  return [text.split(/(\s+)/), text.split(/(\s+)/).length];
}

const getImageSource = ({ imageDownloadURL, imageMediumDownloadURL }) => {
  return imageMediumDownloadURL || imageDownloadURL;
};

const Story2 = (props) => {
  const navigate = useNavigate();
  const { storyId } = useParams();
  const { firestoreUserData } = props;
  const { currentSubscription } = firestoreUserData || {};
  const currentLanguage = firestoreUserData?.currentLanguage;

  // State variables
  const [currentUser, setCurrentUser] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [storyWordDictionary, setStoryWordDictionary] = useState({});
  const [wordBankDictionary, setWordBankDictionary] = useState({});

  // Icon color state
  const [shareStoryIconColor, setShareStoryIconColor] = useState("grey");
  const [basicAiFeaturesIconColor, setBasicAiFeaturesIcon] = useState("grey");
  const [metaDataDisplayColor, setMetaDataDisplayColor] = useState("grey");

  const [cryptoIconInterfaceColor, setCryptoIconInterfaceColor] =
    useState("grey");

  // Icon options bars persisted state
  const [showMetaData, setShowMetaData] = usePagePersist("showMetaData", false);

  const [showShareOptions, setShowShareOptions] = usePagePersist(
    "showShareOptions",
    false
  );

  const [showBasicAiFeatures, setShowBasicAiFeatures] = usePagePersist(
    "showBasicAiFeatures",
    false
  );
  const [showCryptoInterface, setShowCryptoInterface] = usePagePersist(
    "showCryptoInterface",
    false
  );

  // Misc persisted state
  const [linkCopied, setLinkCopied] = usePagePersist("linkCopied", false);
  const [showQRCode, setShowQRCode] = usePagePersist("showQRCode", false);

  const [qrCodeSize, setQrCodeSize] = usePagePersist("qrCodeSize", 50);
  // originally 128, but had to change to 50 to avoid unexpected behavior

  const [deleteInProgress, setDeleteInProgress] = usePagePersist(
    "deleteInProgress",
    false
  );
  const [generatedStaticLink, setGeneratedStaticLink] = usePagePersist(
    "generatedStaticLink",
    ""
  );
  const [loadingStaticLink, setLoadingStaticLink] = usePagePersist(
    "loadingStaticLink",
    false
  );

  const [showTestMaker, setShowTestMaker] = usePagePersist(
    "showTestMaker",
    false
  );

  // const [currentlyGeneratingQuestion, setCurrentlyGeneratingQuestion] =
  //   usePagePersist("currentlyGeneratingQuestion", false);

  const [selectedItem, setSelectedItem] = useState(null);

  const toggleItem = (item) => {
    setSelectedItem((prevItem) => (prevItem === item ? null : item));
  };

  const exportImageRef = useRef(null);

  const readingLevel = null;

  const getPlanType = () => {
    if (!currentSubscription || !currentSubscription.planId) {
      return "basic";
    }
    if (currentSubscription.planId.startsWith("basic")) {
      return "basic";
    }
    if (currentSubscription.planId.startsWith("premium")) {
      return "premium";
    }
    return "basic";
  };

  const {
    loading: storyLoading,
    data,
    error: storyError,
  } = useGetStory(currentUser?.uid, db, storyId);

  const {
    title,
    prompt,
    text,
    imageDownloadURL,
    imageMediumDownloadURL,
    wordBank,
    ageLevel,
    summary,
    characters,
    language,
    languageEmoji,
    dateCreated,
    dateModified,
    quizArray = [],
    illustrationObject = null,
    retryImageGeneration,
    vocabulary,
    conflict,
    resolution,
    setting,
    theme: storyTheme,
  } = data || {};

  // console.log("showBasicAiFeatures", showBasicAiFeatures);
  // // Event handlers
  // console.log("showMetaData", showMetaData);
  // Event handlers

  const toggleFavorited = async () => {
    if (!data) return;
    const favorited = data.favorited !== undefined ? data.favorited : false;
    await updateStory({
      userId: currentUser.uid,
      storyId: storyId,
      db,
      updatedFields: { favorited: !favorited },
    });
  };

  const handleGenerateImage = () => {
    navigate("/newstoryaddpicture", {
      state: { fullStoryObject: data },
    });
  };

  const editStory = () => navigate("/storyeditmode/" + storyId);

  const handleQRCodeClick = () => {
    if (!window.matchMedia("(max-width: 767px)").matches) {
      setQrCodeSize((prevSize) => (prevSize === 128 ? 128 * 3 : 128));
    }
  };

  const generateQRCode = () => {
    if (!generatedStaticLink) {
      generateNewStaticLink({
        setLoadingStaticLink,
        title,
        text,
        quizArray,
        imageDownloadURL,
        imageMediumDownloadURL,
        currentUser,
        baseUrl,
        storyId,
        updateStory,
        db,
        setGeneratedStaticLink,
        setLinkCopied,
      });
    }

    const isMobileDevice = window.matchMedia("(max-width: 767px)").matches;

    if (isMobileDevice) {
      setShowQRCode(true);
      setQrCodeSize(window.innerWidth * 0.9);
    } else {
      setShowQRCode((prev) => !prev);
      setQrCodeSize((prevSize) => (prevSize === 128 ? 128 * 3 : 128));
    }
  };

  // Options bars

  const handleMetaDataDisplay = () => {
    setShowShareOptions(false);
    setShowBasicAiFeatures(false);
    setShowCryptoInterface(false);

    setMetaDataDisplayColor((prev) => (prev === "blue" ? "grey" : "blue"));
    setShowMetaData((prev) => !prev);
  };

  //
  //

  const handleBasicAiFeatures = () => {
    setShowShareOptions(false);

    setShowMetaData(false);
    setShowCryptoInterface(false);

    setBasicAiFeaturesIcon((prev) => (prev === "yellow" ? "grey" : "yellow"));
    setShowBasicAiFeatures((prev) => !prev);
  };

  //
  //

  const handleShareStory = () => {
    setShowShareOptions((prev) => !prev);
    generateQRCode();
    setShareStoryIconColor((prev) => (prev === "green" ? "grey" : "green"));
  };

  //
  //

  const handleCryptoInterface = () => {
    setShowShareOptions(false);
    setShowBasicAiFeatures(false);

    setCryptoIconInterfaceColor((prev) =>
      prev === "purple" ? "grey" : "purple"
    );

    setShowCryptoInterface((prev) => !prev);
  };

  //
  //

  const optionsBarProps = {
    data,
    toggleFavorited,
    handleMetaDataDisplay,
    editStory,
    metaDataDisplayColor,
    handleBasicAiFeatures,
    basicAiFeaturesIconColor,
    handleShareStory,
    shareStoryIconColor,
    title,
    text,
    getImageSource,
    imageDownloadURL,
    imageMediumDownloadURL,
  };

  // Effects
  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((user) => {
      if (user) {
        setCurrentUser(user);
      } else {
        navigate("/login");
      }
      setLoading(false);
    });
    return () => unsubscribe();
  }, [navigate]);

  useEffect(() => {
    let textDictionary = {};
    const [storyTextArray] = splitWords(text);

    storyTextArray &&
      storyTextArray.forEach((word) => {
        if (/^[a-zA-Z ]+$/.test(word)) {
          textDictionary[word.toLowerCase()] = true;
        }
      });
    setStoryWordDictionary(textDictionary);

    let wbd = {};
    let wordBankArray = Array.isArray(wordBank)
      ? wordBank
      : wordBank?.split(/,\s*/);

    wordBankArray?.forEach((word) => {
      if (/^[a-zA-Z ]+$/.test(word.trim())) {
        wbd[word.trim().toLowerCase()] = true;
      }
    });
    setWordBankDictionary(wbd);
  }, [text, wordBank]);

  useEffect(() => {
    localStorage.setItem("imageLocalABC", imageDownloadURL);
  }, [imageDownloadURL]);

  useEffect(() => {
    setShowTestMaker(quizArray.length === 0);
  }, [quizArray]);

  // Cleanup effect
  useEffect(() => {
    return () => {
      // Clear localStorage items
      const itemsToClear = [
        "linkCopied",
        "showQRCode",
        "qrCodeSize",
        "deleteInProgress",
        "generatedStaticLink",
        "loadingStaticLink",
        "showPremiumFeatures",
        "showMetaData",
        "showTestMaker",
        // "currentlyGeneratingQuestion",
        "shareStory",
        "selectedItem",
      ];
      itemsToClear.forEach((item) => localStorage.removeItem(item));
    };
  }, []);

  const options = { year: "numeric", month: "long", day: "numeric" };

  const dateCreatedDisplay =
    dateCreated &&
    new Date(dateCreated.seconds * 1000).toLocaleDateString(undefined, options);
  const dateModifiedDisplay =
    dateModified &&
    new Date(dateModified.seconds * 1000).toLocaleDateString(
      undefined,
      options
    );

  // Add state for PDF modal
  const [pdfModalOpen, setPdfModalOpen] = useState(false);
  const [pdfOptions, setPdfOptions] = useState({
    includeStory: true,
    includeQuiz: false,
    includeVocabulary: false,
  });

  // Add the PDF Modal component
  const PDFOptionsModal = () => (
    <Modal
      open={pdfModalOpen}
      onClose={() => setPdfModalOpen(false)}
      size="small"
    >
      <Modal.Header>PDF Export Options</Modal.Header>
      <Modal.Content>
        <div style={{ padding: "20px" }}>
          <div style={{ marginBottom: "15px" }}>
            <Checkbox
              label="Include Story"
              checked={pdfOptions.includeStory}
              onChange={() =>
                setPdfOptions((prev) => ({
                  ...prev,
                  includeStory: !prev.includeStory,
                }))
              }
            />
          </div>
          <div style={{ marginBottom: "15px" }}>
            <Checkbox
              label="Include Quiz"
              checked={pdfOptions.includeQuiz}
              onChange={() =>
                setPdfOptions((prev) => ({
                  ...prev,
                  includeQuiz: !prev.includeQuiz,
                }))
              }
            />
          </div>
          <div style={{ marginBottom: "15px" }}>
            <Checkbox
              label="Include Vocabulary"
              checked={pdfOptions.includeVocabulary}
              onChange={() =>
                setPdfOptions((prev) => ({
                  ...prev,
                  includeVocabulary: !prev.includeVocabulary,
                }))
              }
            />
          </div>
        </div>
      </Modal.Content>
      <Modal.Actions>
        <Button color="red" onClick={() => setPdfModalOpen(false)}>
          Cancel
        </Button>
        <Button
          color="green"
          onClick={() => {
            // Handle PDF generation with selected options
            handleGeneratePDF(pdfOptions);
            setPdfModalOpen(false);
          }}
        >
          Generate PDF
        </Button>
      </Modal.Actions>
    </Modal>
  );

  // Update the PDF generation trigger
  const handlePDFClick = () => {
    setPdfModalOpen(true);
  };

  // Add this function to handle PDF generation
  const handleGeneratePDF = (options) => {
    const pdfContent = {
      title,
      text: options.includeStory ? text : null,
      quiz: options.includeQuiz ? quizArray : null,
      vocabulary: options.includeVocabulary ? vocabulary : null,
    };

    // Call the default export instead
    PDFGenerator(pdfContent);
  };

  if (loading || storyLoading) {
    return (
      <Lottie
        animationData={simpleLoader}
        loop={true}
        style={{ height: 600, position: "relative" }}
      />
    );
  }

  if (error || storyError) {
    return <div>Error loading data...</div>;
  }

  return (
    <>
      <ToastContainer />
      <Container>
        {deleteInProgress && (
          <Lottie
            animationData={trashBin}
            loop={true}
            style={{ height: 600, position: "relative" }}
          />
        )}

        <StoryHeader title={title} />

        <StoryImage
          retryImageGeneration={retryImageGeneration}
          handleGenerateImage={handleGenerateImage}
          getImageSource={getImageSource}
          data={data}
        />
        <br />
        <StoryText text={text} vocabulary={vocabulary} />

        {/* SubscriptionStatusButton Removed temporarily */}
        {/* <SubscriptionStatusButton currentSubscription={currentSubscription} /> */}

        {(() => {
          const planType = getPlanType();
          switch (planType) {
            case "basic":
              return <OptionsBarBasic {...optionsBarProps} />;
            case "premium":
              return <OptionsBarPremium {...optionsBarProps} />;
            default:
              return <OptionsBarFree {...optionsBarProps} />;
          }
        })()}

        {showMetaData && (
          <FileMetaData
            ageLevel={ageLevel}
            readingLevel={readingLevel}
            prompt={prompt}
            language={language}
            languageEmoji={languageEmoji}
            illustrationObject={illustrationObject}
            dateCreatedDisplay={dateCreatedDisplay}
            dateModifiedDisplay={dateModifiedDisplay}
            currentLanguage={currentLanguage}
            setShowMetaData={setShowMetaData}
            setShowBasicAiFeatures={setShowBasicAiFeatures}
          />
        )}

        {showShareOptions && (
          <ExportStory
            loadingStaticLink={loadingStaticLink}
            generatedStaticLink={generatedStaticLink}
            showQRCode={showQRCode}
            qrCodeSize={qrCodeSize}
            handleQRCodeClick={handleQRCodeClick}
            linkCopied={linkCopied}
            graphicExport={graphicExport}
            generateNewStaticLink={generateNewStaticLink}
            graphicQR={graphicQR}
            generateQRCode={generateQRCode}
            showTestMaker={showTestMaker}
            testImage={testImage}
            //currentlyGeneratingQuestion={currentlyGeneratingQuestion}
            editStory={editStory}
            setLoadingStaticLink={setLoadingStaticLink}
            title={title}
            text={text}
            quizArray={quizArray}
            imageDownloadURL={imageDownloadURL}
            imageMediumDownloadURL={imageMediumDownloadURL}
            currentUser={currentUser}
            baseUrl={baseUrl}
            storyId={storyId}
            updateStory={updateStory}
            db={db}
            setGeneratedStaticLink={setGeneratedStaticLink}
            setLinkCopied={setLinkCopied}
            story2Map={story2Map}
            currentLanguage={currentLanguage}
            onPDFClick={() => setPdfModalOpen(true)}
          />
        )}

        {showBasicAiFeatures && (
          <StoryResources
            userId={currentUser?.uid}
            storyId={storyId}
            currentLanguage={currentLanguage}
            storyLanguage={language}
            chatModelVersion={firestoreUserData?.chatModelVersion}
            showResources={showBasicAiFeatures}
            toggleResources={setShowBasicAiFeatures}
            selectedItem={selectedItem}
            toggleItem={toggleItem}
            storyMap={story2Map}
            {...data}
          />
        )}

        {showCryptoInterface && <h1>Mint NFT coming soon</h1>}

        <PDFOptionsModal />
      </Container>
    </>
  );
};

export default Story2;
