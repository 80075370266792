// TransactionSuccessSubscription.jsx

import React, { useState, useEffect } from "react";
import { Container, Header, Segment, Loader } from "semantic-ui-react";
import { useLocation } from "react-router-dom";
import { auth, db } from "../../firebase";
import { useAuthState } from "react-firebase-hooks/auth";
import { doc, getDoc } from "firebase/firestore";

function TransactionSuccessSubscription() {
  const [user] = useAuthState(auth);
  const [currentCredits, setCurrentCredits] = useState(null);
  const [subscription, setSubscription] = useState(null);
  const [loading, setLoading] = useState(true);
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const product = params.get("product");
  const price = params.get("price");
  const plan = params.get("plan");

  useEffect(() => {
    const fetchUserData = async () => {
      if (user) {
        try {
          const userDoc = await getDoc(doc(db, "users", user.uid));
          if (userDoc.exists()) {
            const userData = userDoc.data();
            setCurrentCredits(userData.credit);
            setSubscription(userData.currentSubscription);
          }
        } catch (error) {
          console.error("Error fetching user data:", error);
        } finally {
          setLoading(false);
        }
      }
    };

    fetchUserData();
  }, [user]);

  if (loading) {
    return <Loader active>Loading...</Loader>;
  }

  return (
    <Container>
      <br />
      <h2>Thank you for your subcription purchase!</h2>
      <Segment>
        {/* {product && price ? (
          <p>
            You successfully purchased: {product} for ${price}
          </p>
        ) : plan ? (
          <p>You successfully subscribed to the {plan} plan</p>
        ) : (
          <p>Your transaction was successful.</p>
        )} */}

        {subscription && (
          <div>
            <Header as="h3">{subscription.displayName} Subscription</Header>
            <p>
              Price: ${(subscription.price / 100).toFixed(2)}/
              {/yearly$/i.test(subscription.planId) ? "year" : "month"}
            </p>
            <p>Billed to credit card ending: x {subscription.lastFour}</p>
            <p>
              Current Period:{" "}
              {new Date(
                subscription.currentPeriodStart.seconds * 1000
              ).toLocaleDateString()}{" "}
              to{" "}
              {new Date(
                subscription.currentPeriodEnd.seconds * 1000
              ).toLocaleDateString()}
            </p>
            <p>Transaction Id: {subscription.id}</p>
            <p>Email receipt mailed to: {user.email}</p>
          </div>
        )}
        <br />
        <br />
      </Segment>
    </Container>
  );
}

export default TransactionSuccessSubscription;
