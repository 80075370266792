// Profile.jsx - USER APP

import React, { useState, useEffect } from "react";
import Logout from "../general/Logout";
import { useNavigate } from "react-router-dom";
import { Container, Button, Modal } from "semantic-ui-react";
import useFirebaseQuery from "../hooks/useFirebaseQuery";
import { makeDate } from "../../helpers/makeDate";
import updateUser from "../api/updateUser";
import Lottie from "lottie-react";
import simpleLoader from "../../images/simpleLoader.json";
import { randomNum } from "../../helpers/randomNumber";
import { capitalizeFirstLetter } from "../../helpers/capitalizeFirstLetter";
import { formatFirestoreTimestamp_v2 } from "../../helpers/formatFirestoreTimestamp_v2";
import { createAvatar } from "@dicebear/core";
import { adventurer } from "@dicebear/collection";
import usePagePersist from "../hooks/usePagePersist";
import { auth, db } from "../../firebase";

function Profile(props) {
  let navigate = useNavigate();
  const { currentUser, firestoreUserData } = props;
  const [edittedAvatar, setEdittedAvatar] = useState(null);
  const [editedName, setEditedName] = useState(null);
  const [isModalOpen, setIsModalOpen] = usePagePersist(
    "profileModalOpen",
    false
  );
  const [modalInput, setModalInput] = usePagePersist("profileModalInput", "");
  const [loading, setLoading] = useState(true);

  const {
    loading: loadingQuery,
    error: errorQuery,
    data: dataQuery,
  } = useFirebaseQuery(currentUser?.uid, db, "stories", "dateCreated");

  const {
    credit,
    textTokens,
    userDisplayName,
    avatarSeed,
    userId,
    currentLanguage,
    currentFlag,
    dateLastLoggedIn,
    dateCreated,
    planType,
    planDetails,
    currentSubscription,
    testPage1,
  } = firestoreUserData || {};

  const storiesListDisplay =
    dataQuery &&
    dataQuery.map((story) => (
      <li key={story?.title}>
        {story?.title}{" "}
        <span style={{ color: "green" }}> {makeDate(story?.dateCreated)}</span>
      </li>
    ));

  // -- AVATAR -- // adventurer ,botttsNeutral
  const avatar = createAvatar(adventurer, {
    seed: avatarSeed ? avatarSeed : userId,
  });
  const svgAvatar = avatar.toDataUriSync();

  const avatarUpdated = createAvatar(adventurer, {
    seed: edittedAvatar ? edittedAvatar : "abc",
  });

  const svgAvatarUpdated = avatarUpdated.toDataUriSync();

  const handleAvatarClick = () => {
    const seed = avatarSeed ? avatarSeed : userId;
    const newSeed = seed + randomNum(10000);
    setEdittedAvatar(() => newSeed);
  };

  // Special Features //
  const discardAvatar = () => {
    setEdittedAvatar(() => null);
  };
  const saveAvatar = ({ userId, db }) => {
    updateUser({ userId, db, updatedFields: { avatarSeed: edittedAvatar } });
    setEdittedAvatar(() => null);
  };

  const handleSaveClick = ({ userId, db }) => {
    updateUser({ userId, db, updatedFields: { userDisplayName: modalInput } });
    setIsModalOpen(false);
  };

  const saveName = ({ userId, db }) => {
    updateUser({ userId, db, updatedFields: { userDisplayName: editedName } });
    setEditedName(null);
  };

  const handleModalOpen = () => {
    setModalInput(userDisplayName);
    setIsModalOpen(true);
  };

  const handleModalClose = () => {
    setIsModalOpen(false);
  };

  const handleInputChange = (e) => {
    setModalInput(e.target.value);
  };

  // Set up an authentication listener once to check user session
  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((user) => {
      if (!user) {
        navigate("/"); // Redirect if no user
      } else {
        setLoading(false); // Set loading to false after authentication check
      }
    });
    return () => unsubscribe();
  }, [navigate]);

  useEffect(() => {
    return () => {
      localStorage.removeItem("profileModalOpen");
      localStorage.removeItem("profileModalInput");
    };
  }, []);

  if (loading) return <div>Loading...</div>;
  if (errorQuery) return <h1>{errorQuery?.message}</h1>;
  if (loadingQuery)
    return (
      <div className="center">
        <Lottie
          animationData={simpleLoader}
          loop={true}
          style={{ height: 600, position: "relative" }}
        />
      </div>
    );

  return (
    <Container>
      {userDisplayName ? (
        <h2
          className="center"
          onClick={handleModalOpen}
          style={{ cursor: "pointer", color: "blue" }}
        >
          {userDisplayName}
        </h2>
      ) : (
        <div className="center">
          <br />
          <br />
          <br />
          <Button onClick={handleModalOpen} color="orange" size="tiny">
            Add Display Name
          </Button>
        </div>
      )}

      <>
        <Modal
          open={isModalOpen}
          onClose={handleModalClose}
          size="tiny"
          className="custom-modal"
          style={{
            position: "fixed",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            height: "auto",
          }}
        >
          <Modal.Content>
            <div style={{ textAlign: "center" }}>
              <div style={{ fontSize: "1.5em", marginBottom: "10px" }}>
                Edit Display Name
              </div>
              <textarea
                className="modal-textarea"
                value={modalInput}
                onChange={handleInputChange}
                style={{
                  fontSize: "2em",
                  width: "100%",
                  height: "50px",
                  resize: "none",
                  padding: "5px",
                }}
              />
            </div>
          </Modal.Content>

          <Modal.Actions style={{ display: "flex", justifyContent: "center" }}>
            <Button
              color="green"
              onClick={() => handleSaveClick({ userId, db })}
            >
              Save
            </Button>
            <Button color="black" onClick={handleModalClose}>
              Cancel
            </Button>
          </Modal.Actions>
        </Modal>
      </>

      {edittedAvatar ? (
        <>
          <div className="center" onClick={handleAvatarClick}>
            {svgAvatar && (
              <img src={svgAvatarUpdated} alt="" height="190px" width="190px" />
            )}
          </div>

          <br />
          <div className="center">
            <Button
              color="green"
              size="mini"
              onClick={() => saveAvatar({ userId, db })}
            >
              {" "}
              🟢 Keep!
            </Button>
            <Button color="grey" size="mini" onClick={discardAvatar}>
              {" "}
              ❌ Toss
            </Button>
            <br />
          </div>
        </>
      ) : (
        <>
          <div
            className="center"
            style={{ cursor: "pointer" }}
            onClick={handleAvatarClick}
          >
            {svgAvatar && (
              <img src={svgAvatar} alt="" height="200px" width="200px" />
            )}
          </div>
        </>
      )}

      <br />

      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "flex-start",
          justifyContent: "center",
          fontSize: "1.2em",
          width: "fit-content",
          margin: "auto",
          color: "blue",
          cursor: "pointer",
        }}
      >
        <span>Email: {currentUser?.email}</span>
        {currentLanguage && (
          <>
            <span onClick={() => navigate("/language-selection")}>
              Language : {currentLanguage} {currentFlag}
            </span>
            <span onClick={() => navigate("/stories")}>
              Books: {dataQuery?.length}
            </span>
          </>
        )}
        <br />
        <span onClick={() => navigate("/purchase-credits")}>
          Credits: {credit}
        </span>
      </div>
      <br />
      <div className="center">
        <Logout {...props} color="black" />
      </div>
      <br />
      <br />
      <br />
    </Container>
  );
}

export default Profile;
