// Stories.jsx

import React, { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { Container, Button, Icon } from "semantic-ui-react";
import DisplayStories from "../general/DisplayStories";
import { collection, onSnapshot, deleteDoc, doc } from "firebase/firestore";
import { bookshelfMap } from "../../constants/siteTextMap";
import { auth, db } from "../../firebase"; // Assuming auth is correctly imported
import speakingIcon from "../../images/ui/talking.png";
import listIcon from "../../images/ui/list.png";
import tilesIcon from "../../images/ui/tiles.png";
import heartLineIcon from "../../images/ui/heart_line.png";
import heartRed from "../../images/ui/heart_red.png";
import calendarIcon from "../../images/ui/calendar.png";
import sortAtoZ from "../../images/ui/alpha-a-z.png";
import sortZtoA from "../../images/ui/alpha-z-a.png";
import updateUser from "../api/updateUser";
import usePagePersist from "../hooks/usePagePersist";
import Lottie from "lottie-react";
import simpleLoader from "../../images/simpleLoader.json"; // Make sure this path is correct

function Stories(props) {
  const { currentUser, firestoreUserData } = props;
  let navigate = useNavigate();
  const [savedStories, setSavedStories] = useState([]);
  const [loading, setLoading] = useState(true);
  const [showScrollToTop, setShowScrollToTop] = useState(false);
  const [selectedStories, setSelectedStories] = useState([]);
  const [showDeleteButton, setShowDeleteButton] = useState(false);
  const [isSortedAZ, setIsSortedAZ] = useState(true);
  const [sortField, setSortField] = useState("date");
  const [showDeleteModal, setShowDeleteModal] = useState(false);

  const [isListView, setIsListView] = usePagePersist(
    "isListView",
    firestoreUserData?.settings?.isListView || false
  );

  const [showFavoritedOnly, setShowFavoritedOnly] = usePagePersist(
    "showFavoritedOnly",
    firestoreUserData?.settings?.showFavoritedOnly || false
  );

  const { currentLanguage } = firestoreUserData || {};

  const showStory = (storyId) => {
    navigate("/story2/" + storyId);
  };

  const sortBy = () => {
    const sortedStories = [...savedStories].sort((a, b) => {
      if (isSortedAZ) {
        return (a.title || "").localeCompare(b.title || "");
      } else {
        return (b.title || "").localeCompare(a.title || "");
      }
    });
    setSavedStories(sortedStories);
    setIsSortedAZ(!isSortedAZ);
  };

  const toggleDisplayFavorited = () => {
    setShowFavoritedOnly((prevState) => !prevState);
  };

  const storiesToDisplay = isListView
    ? savedStories // List view always shows all stories
    : showFavoritedOnly // Tile view respects the favorites filter
    ? savedStories.filter((story) => story.favorited)
    : savedStories;

  const toggleView = () => {
    setIsListView(!isListView);
    if (isListView) {
      setSelectedStories([]);
      setShowDeleteButton(false);
    }
  };

  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  function handleUpdateUserSettings() {
    const settingsUpdate = {
      isListView,
      savedStories: savedStories.map((story) => ({
        id: story.id,
        favorited: story.favorited,
      })),
      showFavoritedOnly,
    };

    // Prepare the updated settings object.
    const updatedSettings = firestoreUserData.settings
      ? { ...firestoreUserData.settings, ...settingsUpdate }
      : settingsUpdate;

    // Update user with new settings.
    updateUser({
      userId: currentUser.uid,
      updatedFields: {
        settings: updatedSettings,
      },
    });
  }

  // Set up an authentication listener once to check user session
  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((user) => {
      if (!user) {
        navigate("/login"); // Redirect if no user
      }

      setLoading(false); // Set loading to false after authentication check
    });
    return () => unsubscribe();
  }, [navigate]);

  // Fetch stories when the user is authenticated and loading is false
  useEffect(() => {
    if (!currentUser || loading) return;

    const unsubscribe = onSnapshot(
      collection(db, "users", currentUser.uid, "stories"),
      (snapshot) => {
        const stories = snapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        setSavedStories(stories);
      }
    );
    return () => unsubscribe();
  }, [currentUser, db, loading]);

  // Update user settings when they change
  useEffect(() => {
    if (!currentUser) return;

    const settings = { isListView, showFavoritedOnly };
    updateUser({ userId: currentUser.uid, updatedFields: { settings } });
  }, [isListView, showFavoritedOnly, currentUser]);

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 200) {
        // Adjust the value as needed
        setShowScrollToTop(true);
      } else {
        setShowScrollToTop(false);
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  // Return null to avoid rendering the component when currentUser is not confirmed
  if (!currentUser) {
    return null;
  }

  const handleCheckboxChange = (storyId) => {
    if (Array.isArray(storyId)) {
      // Handling "check all" or "check none"
      setSelectedStories(storyId);
      setShowDeleteButton(storyId.length > 0);
    } else {
      // Handling individual checkbox
      setSelectedStories((prev) => {
        const newSelection = prev.includes(storyId)
          ? prev.filter((id) => id !== storyId)
          : [...prev, storyId];

        setShowDeleteButton(newSelection.length > 0);
        return newSelection;
      });
    }
  };

  const handleMassDelete = () => {
    // Pass the selectedStories directly to DisplayStories for deletion
    if (selectedStories.length > 0) {
      // Find the DisplayStories component and trigger its delete modal
      setShowDeleteModal(true);
    }
  };

  return (
    <>
      <Container>
        {loading ? (
          <div className="center" style={{ marginTop: "100px" }}>
            <Lottie
              animationData={simpleLoader}
              loop={true}
              style={{ height: 200 }}
            />
          </div>
        ) : (
          <>
            <h2 className="center" style={{ fontSize: "4em" }}>
              {bookshelfMap[currentLanguage]?.bookshelf ||
                bookshelfMap["English"].bookshelf}
            </h2>

            <div className="center">
              <Button
                onClick={toggleView}
                size="large"
                content={
                  isListView ? (
                    <img
                      src={tilesIcon}
                      height="30px"
                      alt="talk"
                      style={{ padding: "0px" }}
                    />
                  ) : (
                    <img
                      src={listIcon}
                      height="30px"
                      alt="talk"
                      style={{ padding: "0px" }}
                    />
                  )
                }
              />

              {!isListView && (
                <>
                  <Button
                    onClick={() => sortBy(sortField)}
                    size="large"
                    content={
                      isSortedAZ ? (
                        <Icon name="sort ascending" />
                      ) : (
                        <Icon name="sort descending" />
                      )
                    }
                  />

                  <Button
                    onClick={toggleDisplayFavorited}
                    content={
                      showFavoritedOnly ? (
                        <img src={heartRed} height="30px" alt="favorite" />
                      ) : (
                        <img src={heartLineIcon} height="30px" alt="favorite" />
                      )
                    }
                    style={{
                      display:
                        savedStories !== null &&
                        savedStories.some((story) => story.favorited)
                          ? "inline-block"
                          : "none",
                    }}
                  />
                </>
              )}

              {showDeleteButton && (
                <Button
                  negative
                  onClick={handleMassDelete}
                  content={`Delete Selected (${selectedStories.length})`}
                />
              )}
            </div>

            <br />
            <DisplayStories
              db={db}
              userId={currentUser?.uid}
              currentUser={currentUser}
              storyHandler={showStory}
              isListView={isListView}
              sortBy={sortBy}
              setSavedStories={setSavedStories}
              savedStories={storiesToDisplay}
              selectedStories={selectedStories}
              setSelectedStories={setSelectedStories}
              onCheckboxChange={handleCheckboxChange}
              showCheckboxes={isListView}
              setShowDeleteButton={setShowDeleteButton}
              showDeleteModal={showDeleteModal}
              setShowDeleteModal={setShowDeleteModal}
            />
            <br />

            {showScrollToTop && (
              <div
                style={{
                  position: "fixed",
                  bottom: "20px",
                  left: "50%",
                  transform: "translateX(-50%)",
                  zIndex: 1000,
                }}
              >
                <Button
                  style={{
                    backgroundColor: "rgba(128, 128, 128, 0.5)", // Gray background with 50% transparency
                    border: "1px solid darkgray",
                    color: "black",
                  }}
                  icon="arrow up"
                  onClick={() =>
                    window.scrollTo({ top: 0, behavior: "smooth" })
                  }
                />
              </div>
            )}
          </>
        )}
      </Container>
    </>
  );
}

export default Stories;
