// NewGroqStoryDisplay_NEW.jsx

import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { Button, Container } from "semantic-ui-react";
import RevealImage from "../../general/RevealImage.jsx"; // Import RevealImage
import { getStorage, ref, getDownloadURL } from "firebase/storage";
import removeQuotesFromEnds from "../../../helpers/removeQuotesFromEnds_v2.js";
import saveNewStory from "../../api/saveNewStory.js";
import adjustUserCredit from "../../api/adjustUserCredit.js";
import pictureLoadingOrb from "../../../images/136808-ai-orb.json";
import Lottie from "lottie-react";
import errorCat from "../../../images/9195-error.json";
import StoryTextDisplayWrapperAnimated from "./StoryTextWrapperAnimated.jsx";

const baseUrl = process.env.REACT_APP_API_URL;

const NewGroqStoryDisplay = ({
  userId,
  storyText,
  language,
  languageEmoji,
  userPrompt,
  illustrationObject,
  generatedStoryId,
  groqModel,
  imageGenerationModel,
  currentUser,
  setShowNavbar,
  firestoreUserData,
  handleTryAgain,
  currentImageUrl,
  setCurrentImageUrl,
  setSuccessfulSave,
  setSavingStory,
  successfulCreditUpdate,
  setSuccessfulCreditUpdate,
}) => {
  const [secondApiCallCurriculum, setSecondApiCallCurriculum] = useState(null);
  const [thirdApiCallArt, setThirdApiCallArt] = useState(null);
  const [serverError, setServerError] = useState(false);
  const [animationComplete, setAnimationComplete] = useState(false); // New state

  const [storyObject, setStoryObject] = useState({
    ageLevel: null,
    archived: false,
    chatModelVersion: null,
    DRA_Level: null,
    fullChatRecord: null,
    illustrationObject: {
      ...illustrationObject,
      illustrationType: null,
    },
    imagePromptGenerated: null,
    imageGenerationModel,
    imagesObject: null,
    imageDownloadURL: null,
    imageThumbMediumURL: null,
    imageThumbDownloadURL: null,
    language,
    languageEmoji,
    prompt: userPrompt,
    text: storyText,
    publicAccess: true,
    storyId: generatedStoryId,
    wordBank: null,
  });

  const storage = getStorage();

  const navigate = useNavigate();

  function getDownloadURLWithRetry(ref, retries = 5, delay = 1000) {
    return new Promise((resolve, reject) => {
      const attemptDownload = (retriesLeft) => {
        getDownloadURL(ref)
          .then(resolve)
          .catch((error) => {
            if (retriesLeft === 0) {
              reject(error);
            } else {
              setTimeout(() => attemptDownload(retriesLeft - 1), delay);
            }
          });
      };
      attemptDownload(retries);
    });
  }

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    setCurrentImageUrl(null);
    console.log("Current Image URL reset to null.");
  }, []);

  useEffect(() => {
    if (storyText && !currentImageUrl) {
      // setCurrentImageUrl(null);

      const illustrationStyle =
        illustrationObject?.firebaseSeederPrompt ||
        "an impressionistic oil painting with loose brushwork, a vibrant color palette, where forms and shapes are suggested rather than explicitly defined.";

      console.log("Payload for third API call:", {
        storyText,
        language,
        groqModel,
        userId,
        illustrationStyle,
      });

      const secondApiCallCurriculumPromise = axios.post(baseUrl + "/gpt4o", {
        storyText,
        language,
      });

      const thirdApiCallArtPromise = axios.post(
        baseUrl + "/groq-prompt-builder",
        {
          storyText,
          groqModel,
          userId,
          illustrationStyle,
          generatedStoryId,
        }
      );

      Promise.all([secondApiCallCurriculumPromise, thirdApiCallArtPromise])
        .then(async ([secondResponse, thirdResponse]) => {
          console.log("Second API Call Response:", secondResponse.data);
          console.log("Third API Call Response:", thirdResponse.data);
          console.log(
            "Third API Call Art Image URL:",
            thirdResponse.data.images?.imageThumbMediumURL
          );

          // Setting responses from API calls
          setSecondApiCallCurriculum(secondResponse.data);
          setThirdApiCallArt(thirdResponse.data);

          try {
            // Fetch all image URLs sequentially
            const imageDownloadURL = await getDownloadURLWithRetry(
              ref(storage, thirdResponse.data.images?.imageDownloadURL)
            );
            const imageThumbMediumURL = await getDownloadURLWithRetry(
              ref(storage, thirdResponse.data.images?.imageThumbMediumURL)
            );
            const imageThumbDownloadURL = await getDownloadURLWithRetry(
              ref(storage, thirdResponse.data.images?.imageThumbDownloadURL)
            );

            // Set currentImageUrl only after all URLs are fetched
            setCurrentImageUrl(imageDownloadURL); // Ensure consistency in URL usage

            // Update storyObject with fetched URLs
            setStoryObject((prev) => ({
              ...prev,
              title: removeQuotesFromEnds(secondResponse?.data?.title),
              blurb: removeQuotesFromEnds(secondResponse?.data?.blurb),
              summary: removeQuotesFromEnds(secondResponse?.data?.summary),
              conflict: removeQuotesFromEnds(secondResponse?.data?.conflict),
              resolution: removeQuotesFromEnds(
                secondResponse?.data?.resolution
              ),
              theme: removeQuotesFromEnds(secondResponse?.data?.theme),
              quizArray: secondResponse?.data?.quizArray,
              vocabulary: secondResponse?.data?.vocabulary,
              characters: secondResponse?.data?.characters,
              imagesObject: thirdResponse.data.images,
              imageDownloadURL, // Consistency: use the same variable as currentImageUrl
              imageThumbMediumURL,
              imageThumbDownloadURL,
            }));
          } catch (error) {
            console.error("Error fetching Firebase image URL:", error.message);
            setServerError(true);
          }
        })
        .catch((error) => {
          console.error("Error in API call:", error.message);
          setServerError(true);
        });
    }
  }, [storyText, language, groqModel, userId, illustrationObject]);

  const handleSaveNewStory = async () => {
    try {
      setSavingStory(true);
      setSuccessfulCreditUpdate(false);

      await saveNewStory({
        currentUser,
        storyObject,
        setShowNavbar,
        setServerError,
        setSuccessfulSave,
      });

      await adjustUserCredit(firestoreUserData);

      setSavingStory(false);
      setSuccessfulSave(true);
      console.log("Credit adjusted successfully.");

      setSuccessfulCreditUpdate(true);
      console.log("successfulCreditUpdate", successfulCreditUpdate);
    } catch (error) {
      console.error("Error in saving story or adjusting credit:", error);
      setSavingStory(false);
    }
  };

  if (serverError) {
    <div>
      <Lottie
        animationData={errorCat}
        loop={true}
        style={{ height: 600, position: "relative" }}
      />
      <h1 className="center">Sorry. There has been an error on the server.n</h1>
      <button onClick={() => navigate("/creategroqstory")}>Try Again</button>
    </div>;
  }

  console.log("serverError===>>>>>", serverError);
  return (
    <Container
      style={{ marginTop: "1em", padding: "1em", background: "#f9f9f9" }}
    >
      {secondApiCallCurriculum && (
        <h2 className="center">{secondApiCallCurriculum?.title}</h2>
      )}

      {/* {!serverError ? (
        <h2>Error on Server</h2>
      ) : currentImageUrl && animationComplete ? (
        <div className="center">
          <RevealImage src={currentImageUrl} alt="ai_image" />
        </div>
      ) : (
        <Lottie
          animationData={pictureLoadingOrb}
          loop={true}
          style={{ height: 200, position: "relative" }}
        />
      )} */}

      {currentImageUrl && animationComplete ? (
        <div className="center">
          <RevealImage src={currentImageUrl} alt="ai_image" />
        </div>
      ) : (
        <Lottie
          animationData={pictureLoadingOrb}
          loop={true}
          style={{ height: 200, position: "relative" }}
        />
      )}
      <br />
      <Container>
        {storyText && (
          <StoryTextDisplayWrapperAnimated
            storyText={storyText}
            wordBank={secondApiCallCurriculum?.vocabulary}
            onAnimationComplete={() => setAnimationComplete(true)}
          />
        )}
        <br />
      </Container>

      {currentImageUrl && !serverError && animationComplete && (
        <>
          <div className="center">
            <Button size="huge" color="green" onClick={handleSaveNewStory}>
              Save Story!
            </Button>
          </div>
          <div className="center" style={{ marginTop: "10px" }}>
            <Button size="huge" color="orange" onClick={handleTryAgain}>
              Try Again
            </Button>
          </div>
        </>
      )}
    </Container>
  );
};

export default NewGroqStoryDisplay;
