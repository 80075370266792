// CreateThemedStory.jsx

import React, { useEffect, useState, useRef } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { Container, Button } from "semantic-ui-react";
import { v4 as uuidv4 } from "uuid";
import { auth } from "../../firebase.js";
import axios from "axios";
import Lottie from "lottie-react";
import "react-toastify/dist/ReactToastify.css";
import coloredCircleAnimation from "../../images/94137-1hour-loading-2.json";
import errorCat from "../../images/9195-error.json";
import usePagePersist from "../hooks/usePagePersist";
import { ILLUSTRATION_AI_PROMPTS } from "../../constants/illustrationsConsts";
import { ILLUSTRATION_BUTTON_IMAGE_URLS } from "../../constants/illustrationsConsts";
import { ILLUSTRATION_STYLES } from "../../constants/illustrationsConsts";
import BasicStoryCreate from "./BasicStoryCreate";
import AdvancedStoryCreate from "./AdvancedStoryCreate";
import buildStory from "../api/buildStoryApi";

import {
  createStoryMap,
  languageSelectionMap,
} from "../../constants/siteTextMap";

import themes from "../../constants/themesConfig";

function CreateThemedStory(props) {
  const { currentUser, avatar, firestoreUserData, showNavbar, setShowNavbar } =
    props;

  const [selectedThemeKey, setSelectedThemeKey] = usePagePersist(
    "selectedThemeKey",
    "defaultTheme"
  );
  const theme = themes[selectedThemeKey];
  const chatModelVersion = process.env.REACT_APP_TEXT_MODEL;
  const imageGeneration = process.env.REACT_APP_IMAGE_MODEL;

  const isMobile = window.innerWidth <= 600;
  const cancelTokenSourceRef1 = useRef(null);
  const intervalIdRef1 = useRef(null);

  const [entireResponse, setEntireResponse] = useState(null); // not using usePagePersist here?

  const [newStory, setNewStory] = usePagePersist("newStory", null);
  const [userPrompt, setUserPrompt] = usePagePersist("userPrompt", null);
  const [advancedUI, setAdvancedUI] = usePagePersist("advancedUI", false);

  const [generatedStoryId, setGeneratedStoryId] = usePagePersist(
    "generatedStoryId",
    uuidv4()
  );
  const [language, setStoryLanguage] = usePagePersist(
    "storyLanguage",
    firestoreUserData?.currentLanguage || "English"
  );

  const [illustrationType, setIllustrationType] = usePagePersist(
    "illustrationType",
    [
      0,
      ILLUSTRATION_STYLES[0],
      ILLUSTRATION_BUTTON_IMAGE_URLS[0],
      ILLUSTRATION_AI_PROMPTS[0],
    ]
  );
  const [illustrationObject, setIllustrationObject] = usePagePersist(
    "illustrationObject",
    {}
  );
  const [loading, setLoading] = usePagePersist("loading", false);
  const [isCancelling, setIsCancelling] = usePagePersist("isCancelling", false);
  const [shouldNavigate, setShouldNavigate] = usePagePersist(
    "shouldNavigate",
    false
  );
  const [errorMessage, setErrorMessage] = usePagePersist("errorMessage", null);

  let navigate = useNavigate();

  const [authLoading, setAuthLoading] = useState(true); // Track auth loading state

  let location = useLocation();

  useEffect(() => {
    return () => {
      localStorage.removeItem("newStory");
      localStorage.removeItem("userPrompt");
      localStorage.removeItem("advancedUI");
      localStorage.removeItem("generatedStoryId");
      localStorage.removeItem("storyLanguage");
      localStorage.removeItem("illustrationType");
      localStorage.removeItem("illustrationObject");
      localStorage.removeItem("loading");
      localStorage.removeItem("isCancelling");
      localStorage.removeItem("shouldNavigate");
      localStorage.removeItem("errorMessage");
      // Clear other persisted states as necessary
    };
  }, []);

  useEffect(() => {
    // Listen to the authentication state but do not set it in state here
    const unsubscribe = auth.onAuthStateChanged((user) => {
      setAuthLoading(false); // Authentication has resolved
      if (!user) {
        navigate("/login"); // Redirect to login if no user
      }
    });
    return () => unsubscribe();
  }, [navigate]);

  useEffect(() => {
    if (location.state?.userPrompt) {
      setUserPrompt(location.state.userPrompt);
    }
  }, [location]);

  useEffect(() => {
    if (shouldNavigate) {
      navigate("/newstorydisplay", {
        state: {
          entireResponse,
          generatedStoryId,
          newStory,
          illustrationObject,
          illustrationType: illustrationType[3],
          userPrompt,
          chatModelVersion,
          imageGeneration,
        },
      });
    }
  }, [
    shouldNavigate,
    navigate,
    entireResponse,
    generatedStoryId,
    newStory,
    illustrationObject,
    illustrationType,
    userPrompt,
    chatModelVersion,
    imageGeneration,
  ]);

  useEffect(() => {
    if (!authLoading && !currentUser) {
      navigate("/login");
    }
  }, [authLoading, currentUser, navigate]);

  // Your existing useEffects and other lo

  useEffect(() => {
    setGeneratedStoryId(uuidv4());
    setShowNavbar(true);
    setIsCancelling(false);
  }, []);

  const clearAllPageState = () => {
    setErrorMessage(null);
    setEntireResponse(null);
    setNewStory(null);
    setShowNavbar(true);
    setIsCancelling(false);
  };

  // refactor this to firebase

  const toggleTheme = null;
  // const toggleTheme = () => {
  //   const nextThemeKey = selectedThemeKey === 'defaultTheme' ? 'valentinesTheme' : 'defaultTheme'; // Toggle logic
  //   setSelectedThemeKey(nextThemeKey);
  // };

  const submitStoryPrompt = (formData) => {
    setShowNavbar(false);
    setLoading(true);

    const userPromptInput = formData.userRequest;
    const additionalData = {
      userId: currentUser?.uid,
      theme: theme?.descriptionForApi,
      language,
    };

    setUserPrompt(userPromptInput);

    cancelTokenSourceRef1.current = axios.CancelToken.source();

    buildStory({
      userPrompt: userPromptInput,
      additionalData,
      chatModelVersion,
      cancelToken: cancelTokenSourceRef1.current.token,
      intervalIdRef1,
    })
      .then((response) => {
        setLoading(false);

        const { originalData, returnvalue, storyData } = response;

        setEntireResponse({
          originalData,
          returnvalue,
          storyData,
        });

        setNewStory(storyData);
        setShouldNavigate(true);
      })

      .catch((error) => {
        setLoading(false); // Stop loading animation
        if (axios.isCancel(error)) {
          console.log("User cancelled the story request");
          // Additional handling if needed for cancellation
        } else {
          setErrorMessage(error.message);
        }
      });
  };

  const paymentRequired = () => navigate("/payment");

  if (errorMessage) {
    return (
      <Container>
        <br />
        <br />
        <h1 className="center">
          {createStoryMap[language]?.serversBusyErrorMessage ||
            createStoryMap["English"].serversBusyErrorMessage}
        </h1>
        <Lottie
          animationData={errorCat}
          loop={true}
          style={{ height: 600, position: "relative" }}
        />
        <div className="center">
          <Button
            onClick={clearAllPageState}
            className="center"
            size="huge"
            color="black"
          >
            {createStoryMap[language]?.continue ||
              createStoryMap["English"].continue}
          </Button>
        </div>
      </Container>
    );
  }

  if (authLoading) {
    return <div>Loading...</div>; // Provide a loading state while authentication is resolving
  }

  if (loading) {
    return (
      <div style={{ position: "relative", height: 600 }}>
        <Lottie
          animationData={coloredCircleAnimation}
          loop={true}
          style={{ width: "100%", height: "100%" }}
        />

        <div
          className="center"
          style={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            zIndex: 1,
          }}
        >
          <h2>{userPrompt}</h2>
        </div>

        <div
          className="center"
          style={{
            position: "absolute",
            bottom: 20,
            left: "50%",
            transform: "translateX(-50%)",
          }}
        >
          <Button
            color="black"
            onClick={() => {
              setIsCancelling(true);

              // Cancel the API request
              if (cancelTokenSourceRef1.current) {
                cancelTokenSourceRef1.current.cancel(
                  "API request cancelled by the user."
                );
              }

              setEntireResponse(null);
              setNewStory(null);
              setUserPrompt(null); // Reset the user prompt
              setAdvancedUI(false);
              setGeneratedStoryId(uuidv4()); // Regenerate the story ID
              setLoading(false);
              setIllustrationObject({});
              setIsCancelling(false); // Reset the cancelling state
            }}
          >
            ❌
          </Button>
        </div>

        {isCancelling && (
          <h1
            style={{
              position: "absolute",
              top: "60%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              zIndex: 1,
            }}
          >
            Cancelling this story
          </h1>
        )}
        <br />
        <br />
        <br />
        <br />
        <br />
      </div>
    );
  }

  return (
    <Container>
      {advancedUI ? (
        <AdvancedStoryCreate
          language={language}
          paymentRequired={paymentRequired}
          isMobile={isMobile}
          illustrationType={illustrationType}
          setIllustrationType={setIllustrationType}
          illustrationObject={illustrationObject}
          setIllustrationObject={setIllustrationObject}
          firestoreUserData={firestoreUserData}
          theme={theme}
          selectedThemeKey={selectedThemeKey}
          toggleTheme={toggleTheme}
          themes={themes}
        />
      ) : (
        <BasicStoryCreate
          language={language}
          paymentRequired={paymentRequired}
          isMobile={isMobile}
          illustrationType={illustrationType}
          setIllustrationType={setIllustrationType}
          illustrationObject={illustrationObject}
          setIllustrationObject={setIllustrationObject}
          submitStoryPrompt={submitStoryPrompt}
          firestoreUserData={firestoreUserData}
          theme={theme}
          selectedThemeKey={selectedThemeKey}
          toggleTheme={toggleTheme} // Pass toggleTheme here
          themes={themes}
        />
      )}
    </Container>
  );
}

export default CreateThemedStory;
